<script setup>
    // ProspectingHomePage
    // Landing page for the prospecting & lead gen program

    // Imports ----
    import { onMounted, reactive, ref, computed } from 'vue';
    import { site } from '@/Site';
    import { Search, TabBar } from '@dd-nucleus/nucleus-vue';
    import { ProductFormat, ProgramType } from '@/constants';
    import { useRouter } from 'vue-router'; // Import useRouter for navigation

    const search = new Search('cross-sell-programs-for-current-agency', 'detail', 'title', 1000);

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import ProspectingOpportunitiesTile from './components/ProspectingOpportunitiesTile';
    import AgencyBestPractices from './components/AgencyBestPractices';
    import IconPanel from '@/site/components/IconPanel';
    import LinkItem from '@/site/components/LinkItem';
    import IgniteProspectingPromo from '@/site/components/IgniteProspectingPromo';

    // State ----
    const prospectingTabs = [
        { id: 'prospecting', title: 'Opportunities & campaigns' },
        { id: 'agency-best-practices', title: 'Agency best practices' }
    ];

    const results = reactive([]);
    const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);

    // Router instance
    const router = useRouter();

    // Lifecycle Hooks
    onMounted(async () => {
        Object.assign(results, await search.results);
        await site.analyticsHandler.push({ "tileCode": "Prospecting and Lead Gen Overview", "lob": "Prospecting" });
    });

    // Computed
    const isEmployee = computed(() => currentAgencyId.value === 0);

    // Methods
    const enroll = () => {
        router.push({
            name: 'ProspectingSelectPromoteProspectsPage'
        });
    };

    const handleEnroll = () => {
        if (!isEmployee.value) {
            enroll();
        } else {
            console.log("Not eligible to enroll.");
        }
    };
</script>

<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar :active-id="TabIDs.PROSPECTING_TAB_ID" />
            <div class="root-page-container prospecting-home-page">
                <div class="row">
                    <div class="col-sm-8">
                        <div class="row">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0"></div>
                                <div class="col-sm-8">
                                    <h1>Prospecting and lead generation</h1>
                                    <p>To build your sales pipeline, start a prospecting campaign today.</p>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <p><b>How it works</b></p>
                                <ul>
                                    <li class="fw-bold">Choose your materials</li>
                                    <p>Options include letters where Safeco supplies prospects, letters where you provide a prospect list and DIY letters you can customize, download and send yourself.</p>
                                    <li class="fw-bold">Customize for your agency</li>
                                    <p>Just follow the steps to customize your letter with your agency's contact information, then add your logo or headshot.</p>
                                    <li class="fw-bold">Target your prospects</li>
                                    <p>Choose a target ZIP code or radius from your agency's ZIP code. Choose prospects with or without phone numbers, enter quantity, then select a mailing schedule.</p>
                                    <li class="fw-bold">Follow up after each mailing</li>
                                    <p>For the best results, reach out to your prospects about a week after the send date to kickstart the sales conversation.</p>
                                </ul>
                            </div>
                            <div class="text-left">
                                <button @click="handleEnroll" class="btn btn-primary">Order now</button>
                            </div>
                        </div>
                        <br />
                        <div class="row mt-3">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0">
                                    <img src="/images/icons/growth-opportunities-icon.png" alt="Growth Opportunities" class="img-fluid" />
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h2>My prospecting</h2>
                                </div>
                            </div>
                        </div>

                        <div class="row" v-if="isEmployee">
                            <div class="col">
                                <AgencyBestPractices />
                            </div>
                        </div>

                        <div class="row" v-else>
                            <div class="col">
                                <TabBar :tabs="prospectingTabs" />
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="prospecting" role="tabpanel">
                                        <div class="sub-tab-container">
                                            <ProspectingOpportunitiesTile filter-id="prospecting" />
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="agency-best-practices" role="tabpanel">
                                        <AgencyBestPractices />
                                    </div>
                                </div>
                            </div>
                        </div>

                        

                        <div class="row mt-3">
                            <div class="col text-left">
                                <h2>Good to know</h2>
                                <ul>
                                    <li>Prospect lists are accessible for <b> 60 days </b> from the date the letters are mailed. A separate list is provided for each mail date specified in your order.</li>
                                    <br />
                                    <li>To view your prospect lists, go to <b> My Account </b> and select  <b> My Order History/Lists </b> from the dropdown menu.</li>
                                    <br />
                                    <li>Need more info before you get started? Check out the <router-link to="/faq">Help Center</router-link>.</li>
                                </ul>
                                <br />
                                <p>Prospecting opportunities may vary by state. If opportunities aren't available, please check back later or reach out to your territory manager to ask about future availability.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm">
                        <div class="row">
                            <div class="col">
                                <IgniteProspectingPromo />
                                <IconPanel class="mt-5 boost-conversation" title="Pro Tip" icon="/images/icons/approvals-target-icon.png">
                                    <span class="title">Boost conversations:</span>
                                    <span class="content"> Follow up with prospects about a week after mailers are sent to increase your chances of connecting.</span>
                                    <br /><br />
                                    
                                </IconPanel>
                                <IconPanel class="mt-5" title="Prospecting resources" icon="/images/icons/search-icon.png">
                                    <LinkItem class="mb-3" title="My prospect list " :link="{ path: '/account/order-history', query: { 'programType': ProgramType.PROSPECTING } }" :isExternal="false" :openNewWindow="false" />
                                    <LinkItem class="mb-3" title="Download letter templates" :link="{ path: '/products', query:{ 'f-program-type': ProgramType.PROSPECTING, 'f-product-format': ProductFormat.LETTER_TEMPLATES, 'active-tab': TabIDs.PROSPECTING_TAB_ID }}" :isExternal="false" :openNewWindow="false" />
                                </IconPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .prospecting-home-page {
        .how-it-works {
            p {
                font-size: 1rem;
            }

            p strong {
                color: $color-2;
                font-size: 1.25rem;
            }

            p span {
                color: $color-2;
            }
        }

        .boost-conversation {
            background: #EBFAFB;

            .title {
                color: #06748c;
                font-weight: bold;
            }

            .content {
                font-weight: 300;
            }
        }
    }
</style>
