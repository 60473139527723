<script setup>

    // PageHeader
    // Used as a header for all pages that use templates/PageTemplate.vue

    // Components ----
    import { PageHeaderContainer, ShowOnlyForRoles } from '@dd-nucleus/nucleus-vue';

    // Imports ----
    import { site } from '@/Site';
    import { ref, computed } from 'vue';
    import { useRouter } from 'vue-router';

    const router = useRouter();

    // State ----
    const keyword = ref('');
    const subMenu = ref(false);
    const menuRef = ref(null);

    // Computed
    const isImpersonating = computed(() => {
        return site.user.isImpersonating;
    })

    const cart = computed(() => {
        return site.cart.current;
    });

    // Handlers ----
    function showMenu() {
        subMenu.value = true;
    }

    function closeMenu() {
        subMenu.value = false;
    }

    function onSearch() {
        router.push({ name: 'ProductSearch', query: { 'f-product-search-term': keyword.value } });
    }

    async function signOut() {
        if (process.env.VUE_APP_MOCK_AUTH_ENABLED == "true") {
            router.push({ name: 'SignIn' });
        } else {
            await site.signOut(router);
        }
    }

    // Click outside the menu
    window.addEventListener('click', (event) => {
        if (menuRef.value && !menuRef.value.contains(event.target)) {
            closeMenu();
        }
    });

</script>

<template>
    <PageHeaderContainer>
        <nav class="main-navbar navbar navbar-expand-lg navbar-nucleus">
            <div class="container-fluid d-flex flex-fill">
                <div class="header-left-container">
                    <router-link to="/home" class="navbar-brand" >
                        <img src="@/../public/images/logo.png" alt="Safeco Marketing Solutions Center">
                    </router-link>
                </div>

                <div class="header-right-container flex-fill d-flex flex-column align-self-start">
                    <div class="menu-container" >
                        <ul class="main-menu navbar-nav me-auto mb-2 mb-lg-0 float-end" ref="menuRef">
                            <ShowOnlyForRoles :roles="site.userRoles.getRolesForAllAccess()" >
                                <li class="nav-item dropdown">
                                    <a id="my-account" @click="showMenu" class="nav-link dropdown-toggle" href="#">
                                        My Account
                                    </a>
                                    <ul class="dropdown-menu show" v-if="subMenu">
                                        <li><a class="dropdown-item" href="#">{{ site.user.userName }}</a></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/profile">My Profile</router-link></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/favorites">My Favorites</router-link></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/contacts">My Contacts</router-link></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/payment-info">My Payment & Billing Info</router-link></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/order-history">My Order History/Lists</router-link></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/downloads">My Custom Downloads</router-link></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/agency-lists">My Uploaded Agency Lists</router-link></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/image-library">My Image Library</router-link></li>
                                        <li><router-link class="dropdown-item" @click="closeMenu" to="/account/save-for-later">My Saved Items</router-link></li>
                                        <!--
        TODO: Uncomment this nav item when are ready for the My Calendar Story
        <li><router-link class="dropdown-item" to="/account/calendar">My Calendar</router-link></li>
    -->
                                    </ul>
                                </li>
                            </ShowOnlyForRoles>
                            <ShowOnlyForRoles :roles="site.userRoles.getRolesForAdminAccess()">
                                <li class="nav-item">
                                    <a class="nav-link" href="/manager/products">|<div class="main-menu-spacer"></div> Admin Center<div class="main-menu-spacer"></div></a>
                                </li>
                            </ShowOnlyForRoles>
                            <li class="nav-item" v-if="!isImpersonating">
                                <a href="#" class="nav-link" @click="signOut">|<div class="main-menu-spacer"></div>Sign Out<div class="main-menu-spacer"></div>|</a>
                            </li>
                            <ShowOnlyForRoles :roles="site.userRoles.getRolesForAllAccess()">
                                <li class="nav-item">
                                    <router-link class="nav-link" to="/cart"><i class="cart-icon bi-cart3"></i> <span v-if="cart.itemCount>0">{{cart.itemCount}}</span> Cart </router-link>
                                </li>
                            </ShowOnlyForRoles>
                        </ul>
                    </div>
                    <div class="search-container d-flex">
                        <div class="flex-fill"></div>
                        <div>
                            <form @submit.prevent="onSearch">
                                <div class="input-group search-group">
                                    <input type="text" class="search-textbox form-control" v-model="keyword" placeholder="Search for programs, marketing materials and more..." aria-label="Search for programs, marketing materials and more...">
                                    <button class="btn btn-color-3-lt btn-search" type="button" @click="onSearch"><i class="icon bi-search"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </PageHeaderContainer>
</template>

<style lang="scss">

    .header-left-container {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
    }

    .header-right-container {
        padding-top: 0.75rem;
    }

    .main-navbar {
        padding: 0rem;
    }

    .menu-container {
        padding-bottom: 1.5rem;
    }

    .main-menu {
        > li:first-child {
            padding-left: 1rem;

            .nav-link {
                padding-right: 1rem;
            }
        }

        > li:last-child {
            padding-left: 1rem;
            padding-right: 0rem;

            .nav-link {
                padding-left: 0rem;
            }
        }

        > li:not(:first-child):not(:last-child) .nav-link {
            padding-left: 0rem;
            padding-right: 0rem;
        }

        > li .nav-link {
            padding-top: 0rem;
            padding-bottom: 0rem;
        }
    }

    .main-menu-spacer {
        display: inline-block;
        width: 1rem;
    }

    .search-group {
        width: 25rem;
    }

    .search-textbox {
        font-size: 0.75rem;
    }

    .btn-search {
        border-radius: 0rem;

        .icon {
            color: $gray-3;
        }
    }

    .dropdown-menu {
        .router-link-exact-active {
            font-weight: bold;
        }
    }
</style>