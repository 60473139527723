<script setup>

    // CrossSellHomePage
    // Landing page for the cross-sell program

    // Imports ----
    import { onMounted, reactive, ref, computed } from 'vue';
    import { site } from '@/Site';
    import { Search, TabBar } from '@dd-nucleus/nucleus-vue';
    import { ProgramType, ProductType } from '@/constants'
    const search = new Search('cross-sell-programs-for-current-agency', 'detail', 'title', 5000);
    //import { PetCrossSellSelfServiceAsset } from '@/constants'

    // Components ----
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import OpportunitiesTile from '@/site/components/OpportunitiesTile';
    import AgencyBestPractices from './components/AgencyBestPractices';
    //import IgniteCrossSellPromo from '@/site/components/IgniteCrossSellPromo';


    // State ----
    const crossSellTabs = [
        {
            id: 'cross-sell',
            title: 'Cross-sell'
        },
        {
            id: 'agency-best-practices',
            title: 'Agency best practices'
        }
    ];

   




    const results = reactive([]);


    const currentAgencyId = ref(site.agencyDetails.agencyDefaultId);
    //const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);

    // Methods  ----

    // Lifecycle Hooks
    onMounted(async () => {
        Object.assign(results, await search.results);
        // For Adobe analytics
        await site.analyticsHandler.push({ "tileCode": "Cross Sell Overview", "lob": "Cross Sell" });
    });

    // Computed
    const isEmployee = computed(() => {
        return currentAgencyId.value == 0 ? true : false;
    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.CROSS_SELL_TAB_ID" />

            <div class="root-page-container cross-sell-home">

                <div class="row">

                    <div class="col-sm-8">

                        <div class="row">

                            <div class="d-flex align-items-center">

                                <div class="flex-shrink-0">


                                </div>

                                <div class="col-sm-100">

                                    <h1>Cross-sell campaigns</h1>
                                    <p style="font-size: 20px;">With so many options available, it's easy to deepen relationships at little to no cost.</p>

                                </div>

                            </div>

                        </div>

                        <div class="row">

                            <div class="col">

                                <p>Choose the approach that works for you - or take advantage of them all.</p>

                                <table>
                                    <thead>
                                        <tr style="border-top: 2px solid black; border-bottom: 2px solid black;">
                                            <th> </th>
                                            <th> Automated cross-sell</th>
                                            <th> Custom cross-sell</th>
                                            <th> DIY cross-sell</th>
                                        </tr>
                                        <tr style="border-bottom: 2px solid #b0adad; ">
                                            <td><strong>How it works</strong></td>
                                            <td>Safeco sends direct mail on your behalf to all your<br /> monoline customers about a month before their<br /> renewal date.</td>
                                            <td>Select and customize your materials; Safeco sends <br /> them on your behalf to the customers you specify.</td>
                                            <td>Select and customize your materials, then download,<br /> print and send to your customers.</td>
                                        </tr>
                                        <tr style="border-bottom: 2px solid #b0adad; text-align: left; vertical-align: top;">
                                            <td><strong>Can I customize?</strong></td>
                                            <td>
                                                No
                                                <br />
                                                <ul>
                                                    <li>Your agency's contact information is printed on the <br /> letters</li>
                                                </ul>
                                            </td>
                                            <td>
                                                Yes
                                                <br />
                                                <ul>
                                                    <li>Add your agency logo or headshot</li>
                                                    <li>Choose direct mail, email or multichannel campaigns</li>
                                                    <li>Specify which customers to target</li>
                                                </ul>
                                            </td>
                                            <td>
                                                Yes
                                                <br />
                                                <ul>
                                                    <li>Add your agency logo or headshot</li>
                                                </ul>
                                            </td>
                                        </tr>

                                        <tr style="border-bottom: 2px solid #b0adad; ">
                                            <td><strong>Cost</strong></td>
                                            <td>Free as part of Safeco&#174; Ignite</td>

                                            <td>Low cost; specialty  free for Ignite Elite agents</td>
                                            <td>Free</td>

                                        </tr>
                                        <tr style="border-bottom: 2px solid #b0adad; ">
                                            <td><strong>Lines of business</strong></td>
                                            <td>Home, auto, umbrella</td>
                                            <td>Home, auto, umbrella, pet, Landlord Protection&#174;,<br /> specialty (motorcycle, watercraft, RV)</td>
                                            <td>Renters, motorcycle, snowmobile, off-road</td>
                                        </tr>
                                        <tr style="border-bottom: 2px solid #b0adad; ">
                                            <td><strong>How to use</strong></td>
                                            <td>Click <b>Enroll</b> below</td>
                                            <td>Click <b> Start Order</b> below</td>
                                            <td>Click a line of business below</td>
                                        </tr>
                                    </thead>
                                </table>
                                <br />
                                <p style="white-space: nowrap; font-size: 85%;">
                                    Availability for cross-sell campaigns may vary by state. Please check back for future updates to availability or reach out to your territory manager with any questions.
                                </p>

                            </div>

                        </div>

                        <div class="row mt-3">

                            <div class="d-flex align-items-center">

                                <div class="flex-shrink-0">

                                    <img src="/images/icons/growth-opportunities-icon.png" alt="Growth Opportunities" class="img-fluid" />

                                </div>

                                <div class="flex-grow-1 ms-3">

                                    <h2>My growth opportunities</h2>

                                </div>

                            </div>

                        </div>

                        <div class="row" v-if="isEmployee">

                            <div class="col">

                                <AgencyBestPractices />

                            </div>

                        </div>

                        <div class="row" v-else>

                            <div class="col">

                                <TabBar :tabs="crossSellTabs" />

                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="cross-sell" role="tabpanel">
                                       
                                        <div class="sub-tab-container">
                                            <OpportunitiesTile filter-id="opportunities-cross-sell"  />
                                        </div>
                                       
                                    </div>

                                    <div class="tab-pane fade" id="agency-best-practices" role="tabpanel">
                                        <AgencyBestPractices />
                                    </div>

                                </div>

                            </div>

                        </div>

                     </div>

                    
                </div>

                <div class="diy-cross-sell">
                    <div class="diy-cross-sell">
                        <h2 style="border-bottom: 2px solid #000; padding-bottom: 5px; display: inline-block;">DIY cross-sell</h2>
                        <p style="margin-top: 10px; font-weight: bold;">Program</p>
                        <table style="width: 100%; border-collapse: collapse;">
                            <tbody>
                               
                                <tr>
                                    <td>
                                        <router-link :to="{
    path: '/products',
    query: {
        'f-product-type': ProductType.RENTERS,
        'f-program-type': ProgramType.CROSS_SELL,

        'product-ids': ['AP-9626E', 'AP-4946E']
    }
}"
                                                     class="no-underline">
                                            Renters
                                        </router-link>
                                    </td>
                                </tr>


                                <tr style="background-color: #f9f9f9;">
                                    <td>

                                        <router-link :to="{
  path: '/products',
  query: {

    'f-product-search-term':'AP-9628E;AP-4945E'
  }
}" class="no-underline">
                                            Motorcycle
                                        </router-link>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <router-link :to="{
  path: '/products',
  query: {
    'f-product-search-term': 'AP-9630E'
  }
}"
                                                     class="no-underline">
                                            Snowmobile
                                        </router-link>
                                    </td>
                                </tr>
                                <tr style="background-color: #f9f9f9;">
                                    <td>
                                        <router-link :to="{
      path: '/products',
      query: {
        'f-product-search-term': 'AP-9343E'
      }
    }"
                                                     class="no-underline">
                                            Off-road
                                        </router-link>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
    </PageTemplate>
</template>
<style lang="scss">
    table {
        width: 80%;
        border-collapse: collapse;
    }
    th,td{
        font-size: 68%;
    }

    th, td {
        border: 1px solid #808080; /* Border for each cell */
        padding: 8px;
        text-align: left;
    }
    tr {
        border-bottom: 2px solid #b0adad; /* Line under each row */
    }

    th {
        border-bottom: 2px solid #808080; /* Bottom border under headers */
    }

    tr {
        border-bottom: 4px solid #808080; /* Border below each row */
    }

    tr:nth-child(even) {
        background-color: #f9f9f9; /* Light grey for alternate rows */
    }

    tr:last-child {
        border-bottom: none; /* Remove bottom border for the last row if needed */
    }

    .cross-sell-note {
        margin-top: 2rem !important;
    }

    

    .diy-cross-sell table {
        width: 100%;
        border-collapse: collapse;
    }

    .diy-cross-sell th {
        padding: 10px;
        font-size: 16px;
        text-align: left;
    }

    .diy-cross-sell td {
        padding: 10px;
        font-size: 16px;
        border: none; /* Remove borders around cells */
    }

    .diy-cross-sell tr {
        border-bottom: 2px solid #b0adad; /* Line under each row */
    }

    .diy-cross-sell tr.highlight {
        background-color: #f9f9f9; /* Light color for highlight */
    }

    .diy-cross-sell td:first-child,
    .diy-cross-sell td:last-child {
        border-left: none; /* Remove left border */
        border-right: none; /* Remove right border */
    }

    .diy-cross-sell td a {
        text-decoration: none;
        color: #007acc;
        //font-weight: bold;*/
    }

    .diy-cross-sell td a::after {
        content: '�'; /* Arrow symbol */
        margin-left: 8px;
        color: #007acc;
       //font-weight: bold;*/
    }


    .diy-cross-sell h2 {
        display: block;
        width: 100%;
        border-bottom: 2px solid #000;
        padding-bottom: 5px;
        margin: 0; /* Remove default margin */
    }
    .diy-cross-sell p {
        display: block;
        width: 100%;
        border-bottom: 2px solid #b0adad;
        padding-bottom: 5px;
        margin: 0; /* Remove default margin */
    }
    .diy-cross-sell {
        padding: 10px; /* Optional: add some padding around the section */
    }
    .diy-cross-sell td a::after {
        content: '> '; /* Greater-than symbol */
        margin-left: 8px;
        color: #007acc;
        font-weight: bold;
    }
    .diy-cross-sell {
        position: relative; /* Enable relative positioning */
        left: -.8%;
    }



</style>
