<script>

    // PersonalizeStep1Page
    // Step 1 Personalization

    const constants = {
        TabTypes: {
            CROSS_SELL: 'CrossSell',
            PROSPECTING: 'Prospecting',
            RETENTION: 'Retention',
            ANNUAL_AUTO: 'AnnualAuto',
            WINBACK: 'Winback',
            DATA_LIST_UPLOAD: 'DataListUpload',
            AGENT_REFERRAL: 'AgencyReferral',
            PET_INSURANCE: 'PetInsurance'
        },
        punchOutActions: {
            CREATE: 'create',
            EDIT: 'edit',
            COMMIT: 'commit'
        }
    };

    // Exports
    export const TabTypes = constants.TabTypes;
    export const punchOutActions = constants.punchOutActions;

</script>
<script setup>


    // Imports
    import { ref, reactive, computed, onMounted } from 'vue';
    import { site } from '@/Site';
    import { useRoute, useRouter } from 'vue-router';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import SelectMailListStepper, { stepCounter } from '@/site/components/SelectMailListStepper';
    import { ChannelTypes } from './SelectChannelPage';
    import { Util } from '@dd-nucleus/nucleus-vue';
    import ThreeStepStepper from '@/site/components/ThreeStepStepper';
    import TwoStepStepper from '@/site/components/TwoStepStepper';
    import PreviousButton from '@/site/components/PreviousButton';
    import { ProductRestrictions, getTabName, ProgramType } from '@/constants';

    // Computed
    const tabName = computed(() => {
        return getTabName(orderFlowDetails.programType);
    });

    const _iOSDevice = computed(() => {
        return /iPad|iPhone|iPod/.test(navigator.platform)
            || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    });

    const displayStepper = computed(() => {
        let displayState = {
            showTwoStepper: false,
            showThreeStepper: false,
            showMailStepper: true,
        };

        if (orderFlowDetails.programType == ProgramType.RETENTION) {
            if (route.query.isSubscription == 'false') {
                displayState.showTwoStepper = true;
                displayState.showMailStepper = false;
            } else {
                displayState.showThreeStepper = true;
                displayState.showMailStepper = false;
            }
        }

        if (orderFlowDetails.programType == ProgramType.ANNUAL_AUTO || orderFlowDetails.programType == ProgramType.PET_INSURANCE) {
            displayState.showThreeStepper = true;
            displayState.showMailStepper = false;
        }
        return displayState;
    })

    // State
    const route = useRoute();

    const detail = reactive({ product: {} });

    const iframeSrc = ref('');

    const frameHeight = ref(1000);

    const showIframe = ref(false);

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    const agencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);

    const directMail = reactive({});

    const email = reactive({});

    const directMailProductDetails = reactive({});

    const emailProductDetails = reactive({});

    const router = useRouter();

    const showButton = ref(false);

    
    // Methods ----
    async function getTicket() {

        let payload = {}
        const BASE_API_URL = process.env.VUE_APP_PAGE_FLEX_RETURN_URL;
        let returnUrl = `${BASE_API_URL}personalization/pageflex-return/${route.params.id}/${orderFlowDetails.parentId}/${orderFlowDetails.productId}/${orderFlowDetails.isMultiChannel}/?channelType=${route.query.channelType}%26programType=${orderFlowDetails.programType}%26isSubscription=${route.query.isSubscription}`;

        if (route.query.review && route.query.review == 'true') {
            returnUrl = `${returnUrl}%26review=${route.query.review}`;
        }
        Object.assign(payload, { ReturnUrl: returnUrl });
        const userInfo = site.user;
        const configInfo = site.config;
        const userName = userInfo.userName.replace(".Test@test.com", '');

        Object.assign(payload, { UserName: userName });

        const agencyName = Util.isNotEmpty(agencyDetails.agencyName) ? encodeURIComponent(agencyDetails.agencyName): agencyDetails.agencyName;
        const firstName = Util.isNotEmpty(userInfo.firstName) ? encodeURIComponent(userInfo.firstName): userInfo.firstName;
        const lastName = Util.isNotEmpty(userInfo.lastName) ? encodeURIComponent(userInfo.lastName): userInfo.lastName;
        const address1 = Util.isNotEmpty(agencyDetails.address1) ? encodeURIComponent(agencyDetails.address1): agencyDetails.address1;
        const address2 = Util.isNotEmpty(agencyDetails.address2) ? encodeURIComponent(agencyDetails.address2): agencyDetails.address2;
        const city = Util.isNotEmpty(agencyDetails.city) ? encodeURIComponent(agencyDetails.city): agencyDetails.city;
        
        Object.assign(payload, { AgencyName: agencyName });
        Object.assign(payload, { AgentName: firstName });
        Object.assign(payload, { AgentLastName: lastName });

        Object.assign(payload, { AgentAddress: address1 });
        Object.assign(payload, { AgentAddress2: address2 });
        Object.assign(payload, { AgentCity: city });
        Object.assign(payload, { AgentZip: agencyDetails.zip });
        Object.assign(payload, { AgentState: agencyDetails.state });
        Object.assign(payload, { AgentPhone: agencyDetails.phone });
        Object.assign(payload, { AgentEmail: agencyDetails.emailAddress });
        Object.assign(payload, { PHONE: agencyDetails.phone });
        Object.assign(payload, { DocumentCode: detail.product.itemNumber });

        if (detail.product && detail.product['attributes'] && detail.product.attributes['restriction'] == ProductRestrictions.NOTFORMA) {
            Object.assign(payload, { NotForMA: true });
        }

        if (detail.product && detail.product['attributes'] && detail.product.attributes['restriction'] == ProductRestrictions.NOTFORCO) {
            Object.assign(payload, { NotForCO: true });
        }

        if (!orderFlowDetails.pageFlexDocumentId) {
            Object.assign(payload, { DocumentId: detail.product.itemNumber });
            Object.assign(payload, { Action: punchOutActions.CREATE });
        } else {
            Object.assign(payload, { Action: punchOutActions.EDIT });
            Object.assign(payload, { DocumentId: site.personalizationInfo.orderFlowDetails.pageFlexDocumentId });
        }

        const response = await site.personalizationInfo.getPunchoutTicket(payload);

        const ticketId = response.ticketId.replace("OK:", "");

        const BASE_PUNCHOUT_URL = process.env.VUE_APP_PAGE_FLEX_PUNCHOUT_URL;

        iframeSrc.value = `${BASE_PUNCHOUT_URL}/UserPMCreateEdit.aspx?ticket=${ticketId}`;

        showIframe.value = true;
    }

    function onLoadIframe() {
        frameHeight.value = window.frames.outerHeight + 100;

        showButton.value = true; 
    }

    async function getOrderStates() {

        const response = await site.personalizationInfo.getOrderStates(route.params.parentId);

        Object.assign(directMail, response.orderStates.find(o => o.channelType === ChannelTypes.DIRECT_MAIL));

        Object.assign(email, response.orderStates.find(o => o.channelType === ChannelTypes.EMAIL));

        getProductData();

    }

    async function getProductData() {

        Object.assign(directMailProductDetails, await site.products.getProductDetail(directMail.productId));

        if (Object.keys(email).length > 0) {
            Object.assign(emailProductDetails, await site.products.getProductDetail(email.productId));
        }

    }

    // Handlers ----
    onMounted(async () => {
        const orderStateId = route.params.id
        if (Util.isNotEmpty(orderStateId)) {
            var response = await site.personalizationInfo.getOrderState(orderStateId);
            var orderState = response.model;
            if (Util.isNotEmpty(route.params.productId)) {                
                Object.assign(detail.product, await site.products.getProductDetail(route.params.productId));
                await getTicket();
                
                if (orderState.isMultiChannel) {
                    await  getOrderStates();
                }
            }
        }
        console.warn("_iOSDevice", _iOSDevice.value);
    })

    function back() {

         if ((route.query.searchType && route.query.searchType == 'product') || (!route.query.searchType && route.query['active-tab'] == 'prospecting') || (route.query['active-tab'] == 'cross-sell') && orderFlowDetails.productType == "Home;LandlordProtection" || route.query['f-product-search-term']) {
            let query = {}
            if (route.query['f-product-search-term']) {
                query['f-product-search-term'] = route.query['f-product-search-term'];
            }
            else {
                query['f-program-type'] = route.query['f-program-type'];
                query['f-product-tatic'] = tabName.value;
                query['f-product-type'] = route.query['f-product-type'];
                query['active-tab'] = route.query['active-tab'];
            }

            router.push({
                name: 'ProductSearch',
                query: query
            });
        }
        else if (route.query.searchType && route.query.searchType == 'productdetail') {
            router.push({
                name: 'ProductDetail',
                params: {
                    id: orderFlowDetails.productId
                }
            });
        }
        else if (route.query.searchType && route.query.searchType == 'favorite') {
            router.push({
                name: 'Account',
                params: {
                    section: 'favorites'
                }
            });
        }
        else {
            router.push({
                name: 'SelectAssetPage',
                query: {
                    statCode: site.agencyDetails.selectedAgencyDetails.statId,
                    productType: orderFlowDetails.productType,
                    programType: orderFlowDetails.programType,
                    isMultiChannel: orderFlowDetails.isMultiChannel,
                    channelType: orderFlowDetails.channelType
                }
            });
        }
    }
</script>

<template>

    <PageTemplate>

        <div class="container root-page">

            <GlobalTabBar v-if="tabName" :active-id="tabName" />

            <div class="root-page-container print-on-demand-customize-step1">

                <div class="row">

                    <div class="col">
                        <TwoStepStepper v-if="displayStepper.showTwoStepper" :currentStep="stepCounter.ONE" />
                        <ThreeStepStepper v-if="displayStepper.showThreeStepper" :currentStep="stepCounter.ONE" />
                        <SelectMailListStepper v-if="displayStepper.showMailStepper" :currentStep="stepCounter.ONE" />

                    </div>

                </div>

                <div v-if="orderFlowDetails.isMultiChannel">
                    <div class="row mt-5">
                        <div class="col multi-channel-headline fw-bold text-center fs-2">
                            Multi-Channel Cross-sell (Direct Mail & Email)
                        </div>
                    </div>

                    <div class="row multi-channel-detail p-2">
                        <div class="col-2">
                            Direct Mail
                        </div>
                        <div class="col-4 border-end">
                            <div class="row">
                                <div class="col-2">
                                    <img :src="directMailProductDetails.thumbnailImageUrl" class="img-fluid" />
                                </div>
                                <div class="col-10">
                                    {{directMailProductDetails.name}}
                                    <div>{{directMailProductDetails.itemNumber}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-2" v-if="Object.keys(email).length > 0">
                            Email
                        </div>
                        <div class="col-4" v-if="Object.keys(email).length > 0">
                            <div class="row">
                                <div class="col-2">
                                    <img :src="emailProductDetails.thumbnailImageUrl" class="img-fluid" />
                                </div>
                                <div class="col-10">
                                    {{emailProductDetails.name}}
                                    <div>{{emailProductDetails.itemNumber}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row my-5">
                    <div class="col-12 text-danger fw-bolder my-3" v-if="_iOSDevice">
                        If you are using an iOS device (iPhone/iPad) please go to Settings > Safari > uncheck the box for Prevent Cross-Site Tracking in order to proceed with Personalization.
                    </div>
                    <div class="col border-bottom border-color-1">

                        <div class="d-flex">
                            <div class="text-color-1">
                                <h2 class="mb-0">Personalize This Item</h2>
                            </div>
                            <div class="ms-auto">
                                {{detail.product.name}} ({{detail.product.itemNumber}})
                            </div>
                        </div>

                    </div>

                    <PreviousButton v-if="showButton" @back="back()"/>

                </div>

                <div class="row" v-if="showIframe">

                    <div class="col">

                        <embed name="punchout" @load="onLoadIframe" :src="iframeSrc" scrolling="auto" width="100%" frameborder="0" :height="frameHeight" />

                    </div>
                </div>
                <PreviousButton v-if="showButton" @back="back()" />
            </div>

        </div>

    </PageTemplate>

</template>

<style lang="scss">
    .print-on-demand-customize-step1 {
        label {
            font-weight: bold;
            font-size: 1rem;
        }

        .multi-channel-headline {
            background: $color-3-dk;
        }

        .multi-channel-detail {
            background: $gray-1;
        }
    }
</style>
