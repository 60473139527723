<script>

    const constants = {
        punchOutActions: {
            CREATE: 'create',
            EDIT: 'edit',
            COMMIT: 'commit'
        }
    };

    // Exports
    export const TabTypes = constants.TabTypes;
    export const punchOutActions = constants.punchOutActions;

</script>
<script setup>
    // Imports
    import { onMounted, ref } from 'vue';
    import { useRoute } from 'vue-router';

    import { site } from '@/Site';

    // Components
    import { Util } from '@dd-nucleus/nucleus-vue';

    // State
    const route = useRoute();

    const loading = ref(false);

    // Handlers ----
    onMounted(async () => {
        const pageFlexDocId = route.query.DocID
        const productId = route.query.productId
        const userName = route.query.userName
        const statCode = route.query.statCode
        const isWelcomeKit = route.query.isWelcomeKit
        const parentId = route.query.parentId

        if (Util.isNotEmpty(pageFlexDocId) && Util.isNotEmpty(userName)) {
            const payload = {
                productUserCustomDownloadModel: {
                    userName: userName,
                    pageFlexDocumentId: pageFlexDocId,
                    action: punchOutActions.COMMIT,
                    productId: productId,
                    statCode: statCode ? statCode : '',
                    isWelcomeKit: Boolean(isWelcomeKit),
                    parentId: parentId
                }
            };
            const response = await site.personalizationInfo.commitPunchOutForDownload(payload);
            if (response.succeeded) {
                const status = response.completed == true
                let redirectUrl = `/personalization/download-customize-asset/${response.customDownloadId}?docId=${pageFlexDocId}&completed=${status}`;

                if (isWelcomeKit) {                   
                    redirectUrl = `${redirectUrl}&isWelcomeKit=${isWelcomeKit}`;
                    if (status) {
                        loading.value = true;
                        setTimeout(() => {
                            loading.value = false;
                            window.parent.location.href = redirectUrl;
                        }, 20000);
                    } else {
                        window.parent.location.href = redirectUrl;
                    }
                } else {
                    window.parent.location.href = redirectUrl;
                }
            }
        }
    });

</script>
<template>
    <div class="row">
        <div class="col-12 loader text-center" v-show="loading">
            <div class="spinner-grow" role="status"></div>
            <p> Your Welcome Kit is being prepared for download...</p>
        </div>
    </div>
</template>


