<script>

    // ProductSearchPage
    // Main product search page

    const constants = {
        oppIds: {
            AutoCrossSell: "H",
            HomeCrossSell: "A",
            UmbrellaCrossSell: "UR",
            SpecialtyCrossSell: "SP",
            LandLordProtectionCrossSell: "LPP"

        },
        productTypes: {
            HOME: "Home",
            AUTO: "Auto",
            WATERCRAFT: "Watercraft,",
            RV: "RV",
            MOTORCYCLE: "Motorcycle",
            LANDLORDPROTECTION: "LandlordProtection"

        },
        DEFAULT_TITLE: 'Search Results',
        DEFAULT_PAGE_SIZE: 12
    };

    export const oppIds = constants.oppIds;
    export const productTypes = constants.productTypes;

</script>
<script setup>

    // Components ----
    import ProductGridTile from './components/ProductGridTile';
    import ProductListTile from './components/ProductListTile';
    import MockProductGridTile from './components/MockProductGridTile';
    import MockProductListTile from './components/MockProductListTile';
    import GlobalTabBar from '@/site/components/GlobalTabBar';
    import ProductFilterAccordian from './components/ProductFilterAccordian';

    // Imports ----
    import { reactive, computed, watch, onMounted, ref } from 'vue';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site'
    import { useRouter, useRoute } from 'vue-router';
    import { isNotEmpty } from '@/util'
    import useEventsBus from '@/site/services/EventBus';
    import { ProgramType } from '@/constants'

    const { bus } = useEventsBus();

    const router = useRouter();
    const route = useRoute();
    const stateFilterId = 'product-state-restriction';


    // State ----
    const selectedCategories = reactive([]);

    const agencyDetails = ref(site.agencyDetails.selectedAgencyDetails);

    const orderFlowDetails = reactive(site.personalizationInfo.orderFlowDetails);

    // Pre-build the search here, so we can hook into its events and properties.
    const productFiltersSearch = new Search('product-filters', 'detail', 'attribute-name-then-by-attribute-value-name', 100);
    productFiltersSearch.activate();

    const search = new Search('product-list', 'detail', 'product-list-newest-to-oldest', constants.DEFAULT_PAGE_SIZE);

    searchProducts();

    function searchProducts() {
        const backToResults = route.query.backToResults;
        if (isNotEmpty(backToResults) && backToResults == 'true') {
            const filters = site.searchHandler.getExistingProductFilters();
            if (isNotEmpty(filters)) {
                if (!filters.find(f => f.filterId == stateFilterId)) {
                    filters.push({ filterId: stateFilterId, values: [''] });
                }
                search.setFilters(filters);
            }
            else {
                search.setFilterValue(stateFilterId, '');
            }
        } else {
            site.searchHandler.removeExistingFilters();
            search.setFilterValue(stateFilterId, '');
        }
    }

    search.onResults(results => {
        site.searchHandler.storeCurrentProductFilters(search.results.filters)
    });

    const nameOrder = {
        "product-tatic": 7,
        "product-state": 6,
        "product-format": 5,
        "product-explore": 4,
        "product-marketing-materials": 3,
        "product-type": 2,
        "program-type": 1
    }

    //computed
    const productFilters = computed(() => {
        var attributes = ['product-personalize', 'product-category', 'product-data-list-type', 'product-restriction', 'product-tag', 'product-data-list-option', 'product-download-file', 'product-is-first-order-free', 'product-ignite-tiers', 'express-order']
        var currentUserRole = site.user.primaryRoleId;
        var isStateRestriction = site.userRoles.stateRestrictionRoles().find(sr => sr == currentUserRole);
        if (isStateRestriction) {
            attributes.push("product-state");
        }
        let searchFilters = productFiltersSearch.results?.rows ? productFiltersSearch.results?.rows.filter(
            (item) => !attributes.find(a => a == item.name)) : [];
        searchFilters.sort((a, b) => nameOrder[a.name] - nameOrder[b.name]);
        return searchFilters;
    });

    const eligibleToCustomize = computed(() => {
        if (isNotEmpty(agencyDetails.value?.statId)) {
            return true;
        }
        const adminRoles = site.userRoles.getRolesForAdminAccess();
        var rec = site.user.roles.filter(r => adminRoles.includes(r.id));
        return rec?.length > 0;
    });


    const sortOptions = [
        { id: 'product-list-newest-to-oldest', text: 'Newest to Oldest' },
        { id: 'product-list-oldest-to-newest', text: 'Oldest to Newest' },
        { id: 'product-list-itemNumber', text: 'Asset Number (A-Z)' },
        { id: 'product-list-itemNumber-desc', text: 'Asset Number (Z-A)' },
        { id: 'product-list-name', text: 'Asset Name (A-Z)' },
        { id: 'product-list-name-desc', text: 'Asset Name (Z-A)' },
        { id: 'ignite-eligible-first', text: 'Ignite eligible first' }
    ];

    const pageSizeOptions = [12, 24, 36, 48, 60];

    async function selected(id) {        
        router.push({
            name: 'SaveForLaterConfirmationPage',
            params: {
                productId: id
            },
            query: route.query
           });
    }

    async function customDownloadSelected(id) {

        if (!eligibleToCustomize.value) {
            site.toast.error("The user's current agency cannot be found, or they're not assigned to a current agency.");
            return;
        }
        orderFlowDetails.productId = id;
        
        router.push({
            name: 'CustomizeDownloadPage',
            query: {
                productId: id,
            }
        });
    }

    const isProspecting = computed(() => {
            let queryParams = route.query;
            let testtype = queryParams['f-program-type'];
            return testtype == ProgramType.PROSPECTING;
        });

    const isCrossSell = computed(() => {
            let queryParams = route.query;
            let testtype = queryParams['f-program-type'];
            return testtype == ProgramType.CROSS_SELL;
        });


    async function downloadSelected(url, fileName) {
        if (url) {
            await site.downloadHandler.handleDownload(url, fileName, true);
        }
        else {
            site.toast.error('Error while downloading the pdf. please contact system administrator', 'Error');
        }
    }

    watch(() => bus.value.get('changeAgency'), async () => {
        await search.refresh();
    });

    // For Adobe analytics
    onMounted(async () => {
        let queryParams = route.query;
        let tileCode = queryParams['f-product-type'];
        let lob = queryParams['f-program-type'];
        let productNames;

        switch (tileCode) {
            case "LandlordProtection":
                tileCode = "Landlord";
                break;
            case "SafecoPackage":
                tileCode = "Package";
                break;
            case "Home":
                tileCode = "Home to Auto";
                break;
            case "Auto":
                tileCode = "Auto to Home";
                break;
        }
        
        

        productNames = "MSC_" + tileCode;

        if (queryParams['active-tab'] && queryParams['active-tab'] == "marketing") {
            lob = "marketing";
            productNames = "";

            if (queryParams['f-product-format'] && queryParams['f-product-format'] == "Brochures") {
                tileCode = "Brochures and Collateral";
            }
            else {
                tileCode = "All Materials";
            }
        }

        await site.analyticsHandler.push({ "tileCode": tileCode, "lob": lob, "productNames": productNames });
    });

</script>

<template>
    <PageTemplate>

        <div class="container">
            <GlobalTabBar :activeId="route.query['active-tab']" />

            <div class="search-page">

                <SearchContainer :search="search" default-view="grid" :deep-link="true">

                    <div class="row">
                        <div class="col col-3 filter-bar">
                            <div class="filter-section">
                                <TextBoxFilter filter-id="product-search-term" placeholder="Search by keyword" />
                            </div>

                            <div class="filter-section" v-for="filter of productFilters" :key="filter.id">
                                <ProductFilterAccordian>
                                    <template #header>
                                        {{ filter.description }}
                                    </template>
                                    <template #options>
                                        <AddFilterCheckBox v-for="option of filter.values"
                                                           :key="option.id"
                                                           :filter-id="filter.name"
                                                           :values="[option.code]"
                                                           :label="option.name" />
                                    </template>
                                </ProductFilterAccordian>
                            </div>
                        </div>

                        <div class="col col-9">
                            <h1>{{ selectedCategories.length === 0 ? constants.DEFAULT_TITLE : selectedCategories[0].name }} <span v-if="search.results.loaded && search.results.rows.length > 0" class="sub-title">({{ search.results.startIndex }} to  {{ search.results.endIndex }} of {{ search.results.totalCount }} results)</span></h1>

                            <div class="row mb-3">
                                <div class="col"><SortOptionsList :options="sortOptions" /></div>
                                <div class="col"><PageSizeList :options="pageSizeOptions" /></div>
                                <div class="col"><ViewModeSelector /></div>
                                <div class="col-4 text-end"><PageSelector /></div>
                            </div>

                            <SearchGrid>
                                <!-- Template for each item in a grid view -->
                                <template v-slot:grid="product">

                                    <ProductGridTile :product="product" @productSelected="selected" @customDownloadSelected="customDownloadSelected" @downloadSelected="downloadSelected" />

                                </template>

                                <!-- Template for each item in a list view -->
                                <template v-slot:list="product">

                                    <ProductListTile :product="product" @productSelected="selected" @customDownloadSelected="customDownloadSelected" @downloadSelected="downloadSelected" />

                                </template>

                                <!-- Template for no results -->
                                <template #empty>
                                    No products were found that match your criteria. <p v-if="isCrossSell">Availability for Cross-sell campaigns may vary by state. Please check back for 
                                    future updates to availability and reach out to your TM with any questions.</p>
                                    <p v-else-if="isProspecting">Availability for Prospecting campaigns may vary by state. Please check back for future updates to availability and reach 
                                    out to your TM with any questions.</p>
                                </template>

                                <!-- Template while results are initially loading -->
                                <template #loading-grid>
                                    <MockSearchGrid>
                                        <MockProductGridTile />
                                    </MockSearchGrid>
                                </template>

                                <template #loading-list>
                                    <!-- TODO -->
                                    <MockSearchGrid>
                                        <MockProductListTile />
                                    </MockSearchGrid>
                                </template>
                            </SearchGrid>

                            <div class="row">
                                <div class="col text-end"><PageSelector /></div>
                            </div>

                        </div>
                    </div>

                </SearchContainer>

            </div>


        </div>
    </PageTemplate>
</template>

<style lang="scss">

    .search-page {
        margin-top: 1rem;

        h1 {
            color: $black;
        }

        .sub-title {
            font-weight: $normal;
            font-size: 50%;
        }
    }

    .filter-section {
        border-top: 1px solid $gray-20;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .filter-section:first-child {
        padding-top: 0rem;
        border-top: 0px;
        padding-bottom: 1rem;
    }

    .filter-section:last-child {
        border-bottom: 0px;
        padding-bottom: 0rem;
    }
</style>
