<script setup>

    // CalendarPanel
    // Shows the agency lists panel of the my account area.

    // Imports ----
    import { ref } from 'vue';
    import { useRouter } from 'vue-router';
    import { Search }  from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';
    import { isEmpty, formatStatCode, isNotEmpty } from '@/util';


    // Components ----

    // Constants ---- 
    const constants = {
        DEFAULT_PAGE_SIZE: 50,
    }
    const router = useRouter();

    const pageSizeOptions = [25, 50, 100, 200];

    const columns = [
        { field: 'itemNumber', title: 'Asset Number', isSortable: true },
        { field: 'itemName', title: 'Asset Name', isSortable: true, custom: true },
        { field: 'title', title: 'Title', isSortable: true, custom: true },
        { field: 'actions', title: 'Actions', custom: true, isSortable: false },
        { field: 'status', title: 'Status', custom: true, isSortable: false },
        { field: 'version', title: 'Version', isSortable: false, custom: true, },
        { field: 'statCode', title: 'StatCode', isSortable: true, custom: true },
        { field: 'agencyName', title: 'Agency Name', isSortable: true},
        { field: 'customizedDate', title: 'Date Created', custom: true, isSortable: true }
    ];

    const sortIds = [
        { field: 'itemNumber', sortName: 'download-item-number' },
        { field: 'itemName', sortName: 'download-item-name' },
        { field: 'statCode', sortName: 'download-statcode' },
        { field: 'agencyName', sortName: 'download-agencyname' },
        { field: 'customizedDate', sortName: 'download-customized-date' },
        { field: 'title', sortName: 'download-title' }
    ];

    const searchTerm = ref('download-customized-date-desc');

    const rowMap = {};
    // Components ----



    // Handlers ----
    const search = new Search('custom-download-list', 'row', 'download-customized-date-desc', constants.DEFAULT_PAGE_SIZE);

    // Methods ----
    function formatDate(date) {
        if (date && date.length > 0) {
            return new Date(date).toLocaleDateString('en-US', {
                year: 'numeric', month: '2-digit', day: '2-digit', hour12: true, hour: "2-digit",
                minute: "2-digit"
            });
        }
        return '';
    }

    function sortSearch(value) {
        const sortField = sortIds.find(s => s.field == value);

        if (!isEmpty(sortField)) {
            if (searchTerm.value.includes(sortField.sortName)) {

                if (searchTerm.value.includes('-desc')) {
                    searchTerm.value = sortField.sortName;
                } else {
                    searchTerm.value = sortField.sortName + '-desc';
                }

            } else {
                searchTerm.value = sortField.sortName;
            }
        } else {
            searchTerm.value = value;
        }


        search.setSortId(searchTerm.value);
    }

    async function downloadCustomAsset(pageFlexDocId) {
        if (!isEmpty(pageFlexDocId)) {
            const userInfo = site.user;
            const userName = userInfo.userName.replace(".Test@test.com", '');
            var url = `/site/punch-out/get-pdf-proof?userName=${userName}&documentId=${pageFlexDocId}`;
            await site.downloadHandler.handleDownload(url, `${pageFlexDocId}.pdf`);
        }
    }


    async function reOrderOrEdit(record) {
        console.warn('record', record);
        let queryParams = {
            productId: record.productId,
            documentId: record.pageFlexDocumentId
        }
        if (isNotEmpty(record.pageFlexOrderId)) {
            queryParams.reorder = 'true';
            queryParams.parentId = record.productUserCustomDownloadId;
        } else {
            queryParams.edit = 'true';
        }
        router.push({
            name: 'CustomizeDownloadPage',
            query: queryParams
        });

    }

    async function sendPageFlexDocumentForReview(row) {
        await site.personalizationInfo.sendPageFlexDocument(row.productUserCustomDownloadId);
    }


    function getVersion(row) {
        const key = row.statCode + "-" + row.productId + "-" + row.title;
        const totalRecords = search.results?.rows?.filter(r => r.statCode == row.statCode && r.productId == row.productId && row.isWelcomeKit && row.title == r.title)?.length;
        if (totalRecords == 2) {
            if (rowMap[key]) {
                return 1
            }
            else {
                rowMap[key] = 2;
                return 2
            }
        }
        return 1;
    }

    function getStatus(row) {
        return isNotEmpty(row.pageFlexOrderId) ? 'Complete' : 'Incomplete';
    }

    function showEmailLink(row) {
        return row && row.isWelcomeKit && isNotEmpty(row.pageFlexOrderId);
    }

</script>

<template>
    <div class="account-panel custom-download-lists-panel">
        <h1 class="cart-page-header mb-3">My Account <span class="text-muted">/ My Custom Downloads</span></h1>

        <SearchContainer :search="search" default-view="list" :deep-link="false">
        <div class="row mb-2">
                    <div class="col-7 d-flex flex-column">
                        <label class="fw-bold">Agency</label>
                        <TextBoxFilter title="Search by agency name or statcode" filter-id="search-custom-downloads" placeholder="Search by agency name or statcode" />
                    </div>
                </div>

                <div class="row mb-2" v-if="search.results.loaded && search.results.rows.length > 0">
                    <div class="d-flex">
                        <div class="me-auto">
                        </div>
                        <div class="px-3">
                            <PageSizeList :options="pageSizeOptions" />
                        </div>
                        <div>
                            <div>
                                <span v-if="search.results.loaded && search.results.rows.length > 0">Showing {{ search.results.startIndex }} of {{ search.results.totalCount }}</span>
                            </div>
                            <PageSelector />
                        </div>
                    </div>
                </div>
                <div class="data-table">
                    <SearchTable :columns="columns" @sort-by="sortSearch">
                        <template v-slot:actions="row">
                        <div class="">
                            <div v-if="row.pageFlexOrderId" class="px-2 action-item me-3" @click="downloadCustomAsset(row.pageFlexDocumentId)">Download</div>
                            <div v-if="row.isWelcomeKit" class="px-2 action-item" @click="reOrderOrEdit(row)">Edit</div>
                            <div v-if="showEmailLink(row)" class="px-2 action-item" @click="sendPageFlexDocumentForReview(row)">Send Email to MC</div>
                        </div>
                    </template>
                    <template v-slot:itemName="row">
                        <div style="white-space: break-spaces">
                            {{row.itemName}}
                        </div>
                    </template>
                    <template v-slot:title="row">
                        <div style="white-space: break-spaces">
                            {{row.title}}
                          </div>
                        </template>
                        <template v-slot:customizedDate="row">
                            {{ formatDate(row.customizedDate) }}
                        </template>
                        <template v-slot:statCode="row">
                            {{ formatStatCode(row.statCode) }}
                        </template>
                    <template v-slot:version="row">
                        {{ getVersion(row)}}
                    </template>
                    <template v-slot:status="row">
                        <span :class="!row.pageFlexOrderId ? 'text-danger':''">{{ getStatus(row) }}</span>
                    </template>
                </SearchTable>
                </div>
                <div class="row">
                    <div class="col text-end fit-content"><PageSelector /></div>
                </div>
                <SearchGrid>

                    <template #empty>
                        <div>
                            You do not currently have any assets to download.
                        </div>
                    </template>

                </SearchGrid>

</SearchContainer>
    </div>
</template>

<style lang="scss">
    .action-item {
        cursor: pointer;
        color: $color-2;
    }

    .custom-download-lists-panel {
        .table > :not(caption) > * > * {
            color: inherit;
            background-color: inherit;
            box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
           }
    }
</style>