<script>
    // MultipleDrops
    // Form finding the start date of the drop

    const constants = {
        frequencyOptions: {
            BI_WEEKLY: 'bi-weekly',
            WEEKLY: 'weekly'
        },
        numberOfMailingsOptions:{
            TWO: 2,
            THREE: 3,
            FOUR: 4
        }
    }

    export const frequencyOptions = constants.frequencyOptions;
    export const numberOfMailingsOptions = constants.numberOfMailingsOptions;

</script>
<script setup>
    

    // Imports ----
    import Datepicker from '@vuepic/vue-datepicker';
    import { addDays, addMonths, lastDayOfMonth } from 'date-fns';
    import { onMounted, ref, defineEmits, computed, defineProps } from 'vue';
    import { isNotEmpty } from '@/util';


    const props = defineProps({
        multipleDropValues: {
            type: Object,
            required: false
        }
    });

    // State ----
    const date = ref();
    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        return `${month}/${day}/${year}`;
    }

    const disabledDates = ref([]);

    const frequency = ref("");

    const numberOfMailings = ref("");

    const minDate = computed(() => addDays(new Date(),2));
   const maxDate = computed(() => lastDayOfMonth(addMonths(new Date(),4)));

    // Handlers ----
    const emit = defineEmits(['change']);

    function removePastDates() {
        // Remove the last 500 days from the calendar 
        for (let i = 0; i < 500; i++) {
            disabledDates.value.push(addDays(new Date(), -i))
        }
    }

    function change() {
        if (isNotEmpty(frequency.value) && isNotEmpty(numberOfMailings.value) && isNotEmpty(date.value))
            console.log("change emit is called");
            emit('change', {
                frequency: frequency.value,
                numberOfMailings: numberOfMailings.value,
                startDate: date.value
            });
    }

    onMounted(async () => {
        removePastDates();
        if (props.multipleDropValues) {
            if (props.multipleDropValues.frequency) {
                frequency.value = props.multipleDropValues.frequency;
            }
            if (props.multipleDropValues.numberOfMailings) {
                numberOfMailings.value = props.multipleDropValues.numberOfMailings;
            }
            if (props.multipleDropValues.startDate) {
                date.value = new Date(props.multipleDropValues.startDate);
                change();
            }
        }
    });

</script>

<template>
    <div class="one-time-drop text-center">
        <div class="mb-5">
            <h2 class="text-center">Choose Frequency</h2>
            <select @change="change" class="form-select w-50 m-auto" v-model="frequency">
                <option value="">Choose Frequency</option>
                <option :value="frequencyOptions.WEEKLY">Weekly</option>
                <option :value="frequencyOptions.BI_WEEKLY">Bi-Weekly</option>
            </select>
        </div>

        <div class="mb-5">
            <h2 class="text-center">Choose Number of Mailings</h2>
            <select @change="change" class="form-select w-50 m-auto" v-model="numberOfMailings">
                <option value="">Choose Number of Mailings</option>
                <option :value="numberOfMailingsOptions.TWO">2</option>
                <option :value="numberOfMailingsOptions.THREE">3</option>
                <option :value="numberOfMailingsOptions.FOUR">4</option>
            </select>
        </div>

        <div class="mb-3">
            <h2 class="text-center">Select Drop Start Date</h2>
            <Datepicker class="w-50 m-auto"
                        v-model="date"
                        :highlight="disabledDates"
                        :disabledDates="disabledDates"
                        :format="format"
                        highlightDisabledDays
                        @update:modelValue="change"
                        placeholder="Select Drop Date"
                        :enable-time-picker="false"
                        :auto-apply="true"
                        :min-date="minDate"
                        :max-date="maxDate"
                        prevent-min-max-navigation
                        week-start="0"
                        :disabled-week-days="[0, 6]" />
        </div>
    </div>
</template>

<style lang="scss">
    .one-time-drop {

    }
</style>