<script>

    // OpportunitiesTile
    // Tile for displaying cross-sell and retention opportunities 

    const constants = {
        crossSellTypes: {
            AutoCrossSell: "H",
            HomeCrossSell: "A",
            UmbrellaCrossSell: "UR",
            SpecialtyCrossSell: "SP",
            LandLordProtectionCrossSell: "LPP",
            PetCrossSell: "PI",
            CustomHome: "H",
            CustomAuto: "A",
            CustomUmbrella: "UR"
        },
        crossSellIds: {
            AutoCrossSell: "cross-sell-auto-to-home",
            HomeCrossSell: "cross-sell-home-to-auto",
            UmbrellaCrossSell: "cross-sell-umbrella-to-auto",
            SpecialtyCrossSell: "specialty-cross-sell",
            LandLordProtectionCrossSell: "landlord-protection",
            PetCrossSell: "pet-cross-sell",
            CustomAuto: "auto-cross-sell",
            CustomHome: "home-cross-sell",
            CustomUmbrella: "umbrella-cross-sell",
        },

        retentionTypes: {
            AnnualAuto: "annual-auto-list-search",
            CustomersUpRenewal: "customers-up-renewal-list-search",
        },
        retentionIds: {
            AnnualAuto: "annual-auto-convert-6-month-to-annual-auto",
            CustomersUpRenewal: "customers-up-for-renewal-in-the-next-3-months",
        },
        programTypes: {
            CROSSSELL: "CrossSell",
            RETENTION: "Retention",
            PROSPECTING: "Prospecting",
            Other: "Other",
            ANNUAL_AUTO: "AnnualAuto",
            WINBACK: "WinBack"
        },
        productTypes: {
            Home: "Home",
            Auto: "Auto",
            Umbrella: "Umbrella",
            Speciality: ["Watercraft", "RV", "Motorcycle"],
            LandLordProtection: "LandlordProtection",
            PetInsurance:"Pet Insurance"
        }
    };

    export const crossSellTypes = constants.crossSellTypes;
    export const crossSellIds = constants.crossSellIds;
    export const programTypes = constants.programTypes;
    export const productTypes = constants.productTypes;
    export const retentionIds = constants.retentionIds;
    export const retentionTypes = constants.retentionTypes;

    export const getCrossSellType = (id) => {
        switch (id) {
            case crossSellIds.AutoCrossSell:
                return crossSellTypes.AutoCrossSell;
            case crossSellIds.HomeCrossSell:
                return crossSellTypes.HomeCrossSell;
            case crossSellIds.UmbrellaCrossSell:
                return crossSellTypes.UmbrellaCrossSell;
            case crossSellIds.SpecialtyCrossSell:
                return crossSellTypes.SpecialtyCrossSell;
            case crossSellIds.LandLordProtectionCrossSell:
                return crossSellTypes.LandLordProtectionCrossSell;
            case crossSellIds.PetCrossSell:
                return crossSellTypes.PetCrossSell;
            case crossSellIds.CustomAuto:
                return crossSellTypes.AutoCrossSell;
            case crossSellIds.CustomHome:
                return crossSellTypes.HomeCrossSell;
            case crossSellIds.CustomUmbrella:
                return crossSellTypes.UmbrellaCrossSell;
            default:
                return '';
        }
    }

    export const getRetentionType = (id) => {
        switch (id) {
            case retentionIds.AnnualAuto:
                return retentionTypes.AnnualAuto;
            case retentionIds.CustomersUpRenewal:
                return retentionTypes.CustomersUpRenewal;
            default:
                return '';
        }
    }

    export const getProgramType = (id) => {
        switch (id) {
            case crossSellIds.SpecialtyCrossSell:
                return programTypes.CROSSSELL;
            case crossSellIds.LandLordProtectionCrossSell:
                return programTypes.CROSSSELL;
            case crossSellIds.PetCrossSell:
                return programTypes.CROSSSELL;
            default:
                return programTypes.CROSSSELL;
        }
    }
    export const getProductType = (id) => {
        switch (id) {
            case crossSellIds.AutoCrossSell:
                return productTypes.Auto;
            case crossSellIds.HomeCrossSell:
                return productTypes.Home;
            case crossSellIds.UmbrellaCrossSell:
                return productTypes.Umbrella;
            case crossSellIds.SpecialtyCrossSell:
                return productTypes.Speciality.join();
            case crossSellIds.LandLordProtectionCrossSell:
                return productTypes.LandLordProtection;
            case crossSellIds.PetCrossSell:
                return productTypes.PetInsurance;
            default:
                return '';
        }
    }
    
</script>
<script setup>

    // Imports ----
    import { watch, ref, defineProps, reactive, computed } from 'vue';
    import { Search } from '@dd-nucleus/nucleus-vue';
    import { site } from '@/Site';
    import useEventsBus from '@/site/services/EventBus';
    import { useRouter } from 'vue-router';
    import { ChannelTypes } from '@/site/areas/personalization/SelectChannelPage';
    import { getOppIdFromOppId, getProgramTypeFromOppId, getProductTypeFromOppId, ProgramType, EmailMultiChannelIds, ProductType, DataListOptionToOppId, TabIDs } from '@/constants';
    import { formatStatCode, isNotEmpty } from '@/util';
    //import { image } from '@/public/images/icons/1854871_MSC_IgniteTag_1x.png'


    const { bus } = useEventsBus();

    const router = useRouter();

    // Components ----
    import TableSkeleton from '@/site/components/skeleton/TableSkeleton';
    import ConfirmationOverlay from '@/site/components/ConfirmationOverlay';

    // Props ----
    const props = defineProps({
        filterId: {
            type: String,
            required: true
        }
    });

    const selectedAgencyDetails = reactive(site.agencyDetails.selectedAgencyDetails);
    // State ----
    const search = new Search('opportunities-search', 'detail', 'title', 5000);

    const showSearch = ref(true);

    const showOverlay = ref(false);
    const agencyCode = ref('');
    const crossSellType = ref('');
    const licenseNumber = ref('');

    const isAgencyMaster = computed(() => {
        return selectedAgencyDetails && selectedAgencyDetails.isOwnerMaster;
    });

    const isLicenseRequired = computed(() => {
        return (selectedAgencyDetails && selectedAgencyDetails.isLicenseNumberRequired);
    })

    // Handlers ----
    function onEnrollClicked(code, id) {
        showOverlay.value = true;
        agencyCode.value = code;
        crossSellType.value = getCrossSellType(id);
    }

    function cancelEnroll() {
        licenseNumber.value = "";
        showOverlay.value = false;
    } 

    async function confirmEnroll() {
        if (crossSellType.value) {
            const response = await site.opportunities.setEnrollment(agencyCode.value, crossSellType.value, licenseNumber.value);
            if (response && response.succeeded) {
                search.refresh();
                site.toast.success("Successfully Enrolled!");
            }
        }
        licenseNumber.value = "";
        showOverlay.value = false;
    }

    async function onStartOrderClicked(oppId, agencyCode, oppType) {
        let derivedOppId = getOppIdFromOppId(oppId);

        const isEmailMultiChannelSupported = EmailMultiChannelIds.filter(em => em == oppId)?.length > 0;


        if (isEmailMultiChannelSupported) {
            router.push({
                name: 'SelectChannelPage',
                query: {
                    programType: getProgramTypeFromOppId(derivedOppId),
                    productType: getProductTypeFromOppId(derivedOppId),
                    oppType: oppType,
                    statCode: agencyCode,
                    oppId: derivedOppId
                }
            });
        }
        else {
            if (derivedOppId === DataListOptionToOppId.PetCrossSell) {
                router.push({
                    name: 'ProductSearch',
                    query: { 'f-program-type': ProgramType.PET_INSURANCE, 'f-product-type': productTypes.PetInsurance, 'active-tab': TabIDs.CROSS_SELL_TAB_ID }
                });
            }
            else if (derivedOppId === crossSellIds.LandLordProtectionCrossSell) {
                router.push({
                    name: 'SelectAssetPage',
                    query: { 'programType': ProgramType.CROSS_SELL, 'productType': productTypes.LandLordProtection, 'oppId': crossSellTypes.LandLordProtectionCrossSell, 'isMultiChannel': 'false', 'channelType': ChannelTypes.DIRECT_MAIL }
                });
            }
            else if (derivedOppId === crossSellIds.CustomUmbrella) {
                router.push({
                    name: 'SelectAssetPage',
                    query: { 'programType': ProgramType.CROSS_SELL, 'productType': ProductType.UMBRELLA, 'oppId': crossSellTypes.UmbrellaCrossSell, 'isMultiChannel': 'false', 'channelType': ChannelTypes.DIRECT_MAIL }
                });
            }
             else if (derivedOppId === crossSellIds.CustomAuto) {
                router.push({
                    name: 'SelectChannelPage',
                    query: { 'programType': ProgramType.CROSS_SELL, 'productType': ProductType.AUTO, 'oppId': crossSellTypes.AutoCrossSell, 'isMultiChannel': 'false', 'channelType': ChannelTypes.DIRECT_MAIL }
                });
            }

             else if (derivedOppId === crossSellIds.CustomHome) {
                 router.push({
                    name: 'SelectChannelPage',
                    query: { 'programType': ProgramType.CROSS_SELL, 'productType': ProductType.HOME, 'oppId': crossSellTypes.HomeCrossSell, 'isMultiChannel': 'false', 'channelType': ChannelTypes.DIRECT_MAIL }
                });
            }

            else {
                router.push({
                    name: 'SelectAssetPage',
                    query: {
                        programType: getProgramTypeFromOppId(derivedOppId),
                        productType: getProductTypeFromOppId(derivedOppId),
                        isMultiChannel: "false",
                        oppId: derivedOppId,
                        channelType: ChannelTypes.DIRECT_MAIL
                    }
                });
            }
        }
    }

    function getEnrollText(isEnrolled) {

        return isEnrolled ? "Enrolled" : "Not Enrolled";
    }

    // Lifecycle ----
    watch(() => bus.value.get('changeAgency'), () => {
        showSearch.value = false;
        setTimeout(() => {
            showSearch.value = true;
        }, 200);
    });

</script>

<template>

    <ConfirmationOverlay v-if="showOverlay">
        <template v-slot:headline>
            <div v-if="isLicenseRequired" class="row mb-4">
                <div class="col-12">
                    <h2 class="confirm">Please enter License Number</h2>
                    <input v-model="licenseNumber" class="w-100" />
                </div>
            </div>
            <h2 class="confirm">Are you sure you want to Enroll?</h2>
        </template>
        <template v-slot:buttons>

            <button class="btn btn-outline-primary me-5" @click="cancelEnroll">Cancel</button>
            <button class="btn btn-primary ms-5" :disabled="isLicenseRequired && licenseNumber.length < 7" @click="confirmEnroll">Enroll</button>

        </template>
    </ConfirmationOverlay>

    <SearchContainer :search="search" default-view="list" :deep-link="true" v-if="showSearch">

        <TextBoxFilter v-show="false" :filter-id="props.filterId" />

        <SearchGrid>
            <template v-slot:list="opps">
                <div style="display: flex; align-items: center; border-bottom:2px solid #000;">
        <h2 style="width:35%">{{ opps.title }}</h2>
        <headlinewithicon v-if="opps.title === 'Automated cross-sell'" class="sub-tab">
            <img src="/images/icons/1854871_MSC_IgniteTag_1x.png" alt="ignite" class="img-fluid" />
        </headlinewithicon>
    </div>

                <table class="table table-striped sub-tab">

                    <thead v-if="opps.addHeaders">
                        <tr>
                            <th scope="col">Program</th>
                            <th scope="col">Opportunities</th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead>

                    <tbody>
                        <template v-if="opps.opps && opps.opps.length > 0">
                            <template v-for="opp in opps.opps" :key="opp.titleHtml">
                                <tr>
                                    <td width="36%">
                                        <div class="multi-row-div" v-html="opp.titleHtml"></div>
                                        <div v-if="opp.subCodeOppCount > 0" class="multi-row-div"></div>
                                    </td>

                                    <td width="30%">
                                        <div class="multi-row-div"><b>{{ opp.oppCount }}</b> <span v-if="opp.agencyCode">({{ formatStatCode(opp.agencyCode) }})</span> opportunities</div>
                                        <div v-if="isAgencyMaster && isNotEmpty(opp.subCodeOppCount)" class="multi-row-div"><b>{{ opp.subCodeOppCount }}</b> sub code opportunities</div>
                                    </td>
                                    <td width="28%">
                                        <div class="multi-row-div">
                                            <template v-if="opp.titleHtml === 'Cross-sell Auto to Home'">
                                                <div v-if="opp.isEnrolled" class="enrolled">
                                                    <p>Enrolled</p>
                                                </div>
                                                <div v-else>
                                                    <button :disabled="opp.oppCount < 1" @click="onEnrollClicked(opp.agencyCode, opp.id)" class="btn btn-primary-sm">Enroll</button>
                                                </div>
                                            </template>
                                            <template v-if="opp.titleHtml === 'Cross-sell Home to Auto'">
                                                <div v-if="opp.isEnrolled" class="enrolled">
                                                    <p>Enrolled</p>
                                                </div>
                                                <div v-else>
                                                    <button :disabled="opp.oppCount < 1" @click="onEnrollClicked(opp.agencyCode, opp.id)" class="btn btn-primary-sm">Enroll</button>
                                                </div>
                                            </template>
                                            <template v-if="opp.titleHtml === 'Cross-sell Umbrella to Auto'">
                                                <div v-if="opp.isEnrolled" class="enrolled">
                                                    <p>Enrolled</p>
                                                </div>
                                                <div v-else>
                                                    <button :disabled="opp.oppCount < 1" @click="onEnrollClicked(opp.agencyCode, opp.id)" class="btn btn-primary-sm">Enroll</button>
                                                </div>
                                            </template>
                                            <template v-if="opp.titleHtml  === 'Pet Cross-sell'">
                                                <div>
                                                    <button :disabled="opp.oppCount < 1" @click="onStartOrderClicked(opp.id, opp.agencyCode, opp.oppType)" class="btn btn-primary-sm">
                                                        Start Order
                                                    </button>
                                                </div>
                                            </template>
                                            <template v-if="opp.titleHtml  === 'Specialty Cross-sell'">
                                                <div>
                                                    <button :disabled="opp.oppCount < 1" @click="onStartOrderClicked(opp.id, opp.agencyCode, opp.oppType)" class="btn btn-primary-sm">
                                                        Start Order
                                                    </button>
                                                </div>
                                            </template>
                                            <template v-if="opp.oppType  === 'LPP'">
                                                <div>
                                                    <button :disabled="opp.oppCount < 1" @click="onStartOrderClicked(opp.id, opp.agencyCode, opp.oppType)" class="btn btn-primary-sm">
                                                        Start Order
                                                    </button>
                                                </div>
                                            </template>
                                            <template v-if="opp.titleHtml  === 'Auto Cross-sell'">
                                                <div>
                                                    <button :disabled="opp.oppCount < 1" @click="onStartOrderClicked(opp.id, opp.agencyCode, opp.oppType)" class="btn btn-primary-sm">
                                                        Start Order
                                                    </button>
                                                </div>
                                            </template>
                                            <template v-if="opp.titleHtml  === 'Home Cross-sell'">
                                                <div>
                                                    <button :disabled="opp.oppCount < 1" @click="onStartOrderClicked(opp.id, opp.agencyCode, opp.oppType)" class="btn btn-primary-sm">
                                                        Start Order
                                                    </button>
                                                </div>
                                            </template>
                                            <template v-if="opp.titleHtml  === 'Umbrella Cross-sell'">
                                                <div>
                                                    <button :disabled="opp.oppCount < 1" @click="onStartOrderClicked(opp.id, opp.agencyCode, opp.oppType)" class="btn btn-primary-sm">
                                                        Start Order
                                                    </button>
                                                </div>
                                            </template>
                                        </div>
                                        </td>

                                    <td width="20%" v-if="props.filterId == 'opportunities-cross-sell'">
                                        <div class="multi-row-div">
                                            <template v-if="opp.oppCount > 0">
                                                <router-link :to="`/cross-sell/view-detail/${getCrossSellType(opp.id)}/${opp.agencyCode}?enrollText=${getEnrollText(opp.isEnrolled)}`" class="no-underline">View List</router-link>
                                            </template>
                                        </div>
                                        <div v-if="opp.subCodeOppCount > 0" class="multi-row-div">
                                            <router-link :to="'/cross-sell/view-summary/' + opp.id" class="no-underline">View List</router-link>
                                        </div>
                                    </td>

                                    <td width="20%" v-if="props.filterId == 'opportunities-retention'">
                                        <div class="multi-row-div">
                                            <template v-if="opp.oppCount > 0">
                                                <router-link :to="'/retention/annual-auto/view-detail/' + getRetentionType(opp.id)" class="no-underline">View List</router-link>
                                            </template>
                                        </div>
                                        <div v-if="opp.subCodeOppCount > 0" class="multi-row-div">
                                            <router-link to="#TODO" class="no-underline">View List</router-link>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                        <!-- Template for no results -->
                        <template v-else>
                            No opportunities were found that match your criteria.
                        </template>
                    </tbody>
                </table>
            </template>

            <template #loading>
                <TableSkeleton />
            </template>

        </SearchGrid>

    </SearchContainer>

</template>

<style lang="scss" scoped>

    .enrolled {
        display: flex;
        align-items: center;
        p {
            margin: 0px;
            font-size: 12px;
            font-weight: $bold;
            padding-left: 0.5rem;
        }

        button {
            margin-left: 20px;
        }
    }

    .btn-primary-sm {
        padding-left: 1rem;
        padding-right: 1rem;
    }

    .multi-row-div {
        height: 2rem;
        vertical-align: middle;
    }

    .vertical-align {
        vertical-align: middle;
    }

</style>