<script setup>

    // MarketingHomePage
    // Landing page for marketing materials
    import { useRouter } from 'vue-router';
    import { onMounted } from 'vue';
    import { site } from '@/Site';

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';

    //State
    const router = useRouter();

    function searchAllMarketingMaterials() {
        router.push({ path: '/products', query: { 'active-tab': TabIDs.MARKETING_TAB_ID } });
    }

    // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "Marketing Materials", "lob": "Marketing" });
    });
</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.MARKETING_TAB_ID" />

            <div class="root-page-container marketing-materials">

                <div class="row">

                    <div class="col-sm-6">

                        <div class="d-flex align-items-center">
                            <div class="flex-grow-1 ms-3">
                                <h1>Marketing materials</h1>
                                <p class="sub-heading" style="font-size: 24px;">Increase sales with Safeco product brochures and marketing collateral.</p>
                            </div>
                        </div>
                        <ul>
                            <li> <b>Choose the products and programs you'd like to promote</b> <br />Most items are available as free digital downloads so you can easily email them to customers or print them locally. Some items can be printed and shipped at low cost.</li>
                           <br /> <li> <b>Customize for your agency </b>  <br /> Whether print or digital, most items can be customized. Just follow the steps to add your name, contact information and agency logo or headshot at checkout.</li>
                        </ul>


                        <div class="d-flex justify-content-between mt-5">
                            <button class="btn btn-primary ms-2" @click="searchAllMarketingMaterials()">
                                Browse now
                            </button>
                        </div>
                    </div>

                    <div class="col-sm">
                        <div class="text-center">
                            <img src="/images/marketing/marketing-materials.png" alt="marketing materials" class="img-fluid" />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    </PageTemplate>
</template>
