// UserRoles
// Services and actions related to User Roles.

// Imports ----

export default class ErrorHandler {

    // Constructor
    constructor(store) {
        // This will typically be a subclass of NucleusStore
        this.store = store;
    }

    handleStartOrderError(response) {
        if (response && response.succeeded) {
            return true;
        }
        else {
            if (response && response.messages && response.messages.length > 0) {
                this.store.toast.error(response.messages[0].debugMessage, 'Error');
            }
            else {
                this.store.toast.error("Error in this asset, please contact system administrator", 'Error');
            }
        }
    }

    handleApplyDiscountError(response) {
        if (response && response.succeeded) {
            return true;
        }
        else {
            this.store.toast.error('The discount code you entered is invalid.', "Discount cannot be applied.");
        }
    }
    canStartOrder(productId = null) {
        if (this.store.personalizationInfo.getExpressOrderItemInCart()) {
            this.store.toast.error("An Express Order is already in the cart. Please complete the Express Order or remove the item from the cart to proceed.", 'Error');
            return false;
        }
        else if (this.store.personalizationInfo.getOrderItemInCart(productId)) {
            this.store.toast.error("Sorry, we are unable to accommodate multiple versions for the same asset within a single order. Please place a separate order for each.", 'Error');
            return false;
        }
        return true;
    }
    canStartExpressOrder() {
        if (this.store.personalizationInfo.getItemInCart()) {
            this.store.toast.error("An Express Order cannot be started if other items are already in the cart. Please complete your order in progress and create a separate Express Order.", 'Error');
            return false;
        }
        return true;
    }


    async handleSaveForLaterRequest() {
        const response = await this.store.personalizationInfo.enableSaveForLater();
        if (response.succeeded) {
            this.store.toast.success("Your item has been saved in My Account - My Saved Items.");
            return true            
        } else {
            this.store.toast.error("Error while saving items for later use. Please contact administrator");
            return false;
        }
    }
}
