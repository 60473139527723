<script setup>
    // RelationshipMarketingPage
    // Landing page Relationship Marketing

    // Imports
    import { useRouter } from 'vue-router';
    import { onMounted } from 'vue';
    import { site } from '@/Site';

    const router = useRouter();

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    //import HeadlineWithIcon from '@/site/components/HeadlineWithIcon';


    // Methods
    function subscribe() {
        router.push({
            name: 'ProductSearch',
            query: {
                'f-product-search-term': 'AP-3954M'
            }
        });
    }

    function shopNoteCards() {

        router.push({
            name: 'ProductSearch',
            query: {
                "f-product-search-term": 'notecard'
            }
        });
    }

    // For Adobe analytics
    onMounted(async () => {
        await site.analyticsHandler.push({ "tileCode": "Relationship Marketing", "lob": "Retention", "productNames": "MSC_Relationship Marketing" });
    });
</script>
<template>
    <PageTemplate>
        <div class="container root-page">
            <GlobalTabBar :active-id="TabIDs.RETENTION_TAB_ID" />
            <div class="root-page-container retention-home">
                <div class="row align-items-start">
                    <div class="col-sm-6">
                        <h1 class="main-headline">Retention and loyalty</h1>
                        <p class="sub-heading" style="font-size: 24px;">Increase retention by staying top of mind with subscription-based or ad hoc relationship marketing.</p>
                        <br />
                        <h3 style="color: black;">Automated relationship marketing cards</h3>
                        <ul>
                            <li>Choose a 3- or 6-month subscription of greeting cards.</li>
                            <li>We'll send one seasonal card per 3-month period to customers you select.</li>
                            <li>Customers who have a birthday within the subscription period will also get a birthday card.</li>
                        </ul>
                        <div class="text-left">
                            <button @click="subscribe()" class="btn btn-primary">Subscribe</button>
                        </div>
                        <br />
                        <h3 style="color: black;">Customizable note cards</h3>
                        <ul>
                            <li>Choose from thank you, birthday and seasonal messages.</li>
                            <li>Most cards are blank for maximum flexibility.</li>
                            <li>Select styles can be customized with your agency's contact information - just follow the steps at checkout.</li>
                        </ul>
                        <div class="text-left">
                            <button @click="shopNoteCards()" class="btn btn-primary me-5">Shop now</button>
                        </div>
                    </div>

                    <div class="col-sm-5 text-end">
                        <img src="/images/retention/new-year.jpg" alt="new year" class="img-fluid" />
                    </div>
                </div>
            </div>
        </div>
    </PageTemplate>
</template>


<style lang="scss" scoped>
    .retention-home {
        ul {
            padding-left: 30px;

            li {
                list-style-type: disc;
                padding-left: 0px;
                margin-bottom: 10px;
                line-height: 1.5;
            }
        }

        .text-center img {
            max-width: 100%; // Make the image responsive
            height: auto; // Maintain aspect ratio
        }
    }
</style>
