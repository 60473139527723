<script setup>

    // FrequentlyAskedQuestionsPage
    // Frequently Asked Questions with a toggle for questions and answers

    // Components
    import PageTemplate from '@/template/PageTemplate';
    import GlobalTabBar, { TabIDs } from '@/site/components/GlobalTabBar';
    import HelpCenterSidebar from '@/site/areas/help-center/components/HelpCenterSidebar';
    import CollapsiblePanel from '@/site/areas/help-center/components/CollapsiblePanel';
    import HeadlineContainer from '@/site/components/HeadlineContainer';
    //import LinkItem from '@/site/components/LinkItem';

    // Imports
    import { ref, computed } from 'vue';

    // State -----
    const open = ref(false);

    // Methods ----
    function expandAll() {
        open.value = !open.value;
    }

    // Computed ----
    const viewHideText = computed(() => {
        if (open.value) {
            return 'Hide All';
        } else {
            return 'View All';
        }
    });

</script>

<template>
    <PageTemplate>
        <div class="container root-page">

            <GlobalTabBar :active-id="TabIDs.HOME_TAB_ID" />

            <div class="frequently-asked-questions">

                <div class="row">

                    <div class="col-3 mt-5">
                        <HelpCenterSidebar />
                    </div>

                    <div class="col mt-5">
                        <h1>Frequently Asked Questions</h1>

                        <div class="text-end fw-bold text-color-1">
                            <a href="/downloads/AP-5702-E-Marketing-Solutions-Center-Frequently-Asked-Questions-Updated.pdf" target="_blank" class="no-underline">Download FAQ PDF</a> <span class="text-color-2-dk px-3">|</span> <a href="#" class="no-underline" @click="expandAll">{{viewHideText}}</a>
                        </div>

                        <HeadlineContainer class="my-3" title="Overview" />

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is the Marketing Solutions Center integrated with the Ignite rewards program?
                            </template>
                            <template v-slot:content>
                                <p>Yes. You'll see notifications throughout the site indicating your available Ignite rewards, such as for the Cross-sell Program, Prospecting Program and more. Rewards are applied automatically to eligible orders - no need to enter a code at checkout.</p>
                            </template>
                        </CollapsiblePanel>

                        <!--<CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is the MSC integrated with the Ignite platform?
                            </template>
                            <template v-slot:content>
                                <p>Yes. The MSC supports the Ignite platform by offering tools and programs to help agents grow. For example, Cross-sell, referral, lead generation, and more.</p>
                            </template>
                        </CollapsiblePanel>-->

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is the Marketing Solutions Center secure?
                            </template>
                            <template v-slot:content>
                                <p>This site uses industry-standard Secure Sockets Layer (SSL) technology to encrypt:</p>
                                <ul>
                                    <li>Critically sensitive data, such as credit card information</li>
                                    <li>Potentially sensitive information, such as your name and address</li>
                                </ul>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                My default agency information is incorrect on the Marketing Solutions Center. How do I change it?
                            </template>
                            <template v-slot:content>
                                <p>You can update your information during the order process for customizable materials. Unfortunately, the updates won't be saved for future orders. To have your agency information permanently updated, please contact your territory manager.</p>    
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How does Safeco select prospects for the Prospecting Program?
                            </template>
                            <template v-slot:content>
                                <p>We filter third-party data for potential matches to our preferred customer profile. Although they might not be actively shopping for insurance, the resulting prospects are likely to be a good fit for Safeco and your agency. Prospects are dedicated to your agency for 60 days.</p>
                            </template>
                        </CollapsiblePanel>

                        <HeadlineContainer class="my-3" title="Navigation" />

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I find what I need?
                            </template>
                            <template v-slot:content>
                                <p>At the top right of any page, use the search bar to search by keyword. Other ways to find what you need:</p>
                                <ul>
                                    <li>To narrow search results by category, product, format or program, use the filters on the search results page.</li>
                                    <li>Hover over the tabs for <b> Cross-sell</b> and <b> Prospecting </b> to choose a specific product you'd like to focus on. From there you can start an order or use the filters to further refine your search.</li>
                                </ul>
                            </template>
                        </CollapsiblePanel>

                        <!--<CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How can I find the brochure or collateral that I'm looking for?
                            </template>
                            <template v-slot:content>
                                <p>All available brochures can be found with the Advanced Search Feature. You can find this feature in the support tab at the top of the page, denoted by a magnifying glass. The results of your search will deliver all available resources or materials that match your search criteria.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Where can I order supplies?
                            </template>
                            <template v-slot:content>
                                <p>
                                 While the MSC offers marketing programs to help sell Safeco products or retain your existing customers, there are other materials available to your agency for day-to-day operations. Order these miscellaneous items (such as envelopes, letterhead and more) via the <LinkItem style="display: inline-block;"  title="Branded Supplies" link="/marketing#Branded Supplies" :isExternal="true" :openNewWindow="true" :showChevron="false" /> tab under <em>Marketing Materials</em>. For other items, please contact your Territory Manager.
                                </p>
                            </template>
                        </CollapsiblePanel>-->

                        <HeadlineContainer class="my-3" title="Ordering" />

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Where can I find information about my orders?
                            </template>
                            <template v-slot:content>
                                <p>Look at the top right of any page and go to <b> My Account > Order History/Lists</b> to find the details of orders you've placed.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I know when my order has shipped?
                            </template>
                            <template v-slot:content>
                                <p>You'll receive an email confirming your order details upon submission. You'll get a second email when the order ships, which includes the ship date and tracking number.</p>        
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Will I get a sample of my direct mail order delivered to my office?
                            </template>
                            <template v-slot:content>
                                <p>Yes, as a reminder that mail is being delivered to your customers or prospects, we'll mail a sample piece to the contact information listed on the letter.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How can I access my mailing list?
                            </template>
                            <template v-slot:content>
                                <p>Look at the top right of any page and go to <b> My Account > Order History/Lists</b>. The mailing list is available to download the day after the mail date. You can locate the mail date for your order within the confirmation email you'll receive once your order has been mailed.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I know when my direct mail order will be shipped?
                            </template>
                            <template v-slot:content>
                                <p>You can locate the mail date for your order within the confirmation email that's sent to you once your order has been completed.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I customize or personalize brochures or direct mail with my agency information?
                            </template>
                            <template v-slot:content>
                                <p>The information we have on file for your agency will be displayed during the ordering process, and you can make updates before you place your order. Please note:</p>
                                <ul>
                                    <li>Click on the PDF preview to be sure your contact name and phone number are accurate, then click the checkbox to indicate your approval.</li>
                                    <li>You may modify your order by calling the MSC Help Desk at (800) 845- 9860 <b>within 24 hours</b> of placing your order.</li>
                                    <li>Your revised agency information will be printed on the marketing piece but will not be stored for future orders.</li>
                                    <li>To have your agency information permanently updated, please contact your territory manager.</li>
                                </ul>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How do I upload my agency logo or my headshot photo?
                            </template>
                            <template v-slot:content>
                                <p>It's best to add images to your account before you start an order. On the top right of any page, go to <b> My Account > My Images Library</b> and click <b>Select Files</b> to begin. Tips to ensure a clear, crisp image:</p>
                                <ul>
                                    <li>Upload high-resolution images of 300 DPI minimum. The higher the DPI, the clearer the photo or logo will be once it's printed.</li>
                                    <li>Acceptable file formats include vector image, EPS, JPEG, TIF and TIFF.</li>
                                    <li>For photos, a headshot including the shoulders with a solid color background works best.</li>
                                    <li>Most digital cameras and phones will produce an image that will meet these requirements.</li>
                                </ul>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is it possible to return items?
                            </template>
                            <template v-slot:content>
                                <p>Sorry, we are unable to accept returned merchandise.</p>
                            </template>
                        </CollapsiblePanel>

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Is it possible to cancel orders?
                            </template>
                            <template v-slot:content>
                                <p>If you ordered a prospect mailing where Safeco provides prospect names, the order cannot be cancelled after it is placed.</p>
                                <p>If you ordered a mailing to policyholders within your book of business or if you ordered a customized printed brochure, you may modify or cancel your order by calling the MSC Help Desk at (800) 845- 9860 <b> within 24 hours </b>  of placing your order.</p>
                            </template>
                        </CollapsiblePanel>

                        <!--<CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How does the pricing work on brochures?
                            </template>
                            <template v-slot:content>
                                <p>In most cases, you are eligible to receive a limited quantity of brochures or collateral at no charge per quarter. The MSC does allow you to purchase additional quantities beyond the free quarterly allowance for an additional cost.</p>
                            </template>
                        </CollapsiblePanel>-->

                        <!--<CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                What is the benefit of customizing brochures for Print-on-Demand (POD)?
                            </template>
                            <template v-slot:content>
                                <p>Customization is an excellent enhancement to your marketing efforts. The benefit of Print-on-Demand (POD) is the ability to customize the piece with your agency information including phone number and website address. The brochures are then professionally printed on high-quality paper at a price lower than most local copy centers, and shipped directly to you.</p>
                            </template>
                        </CollapsiblePanel>-->

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                I ordered the maximum number of items available for free. Can I order more?
                            </template>
                            <template v-slot:content>
                                <p>Yes, for an additional cost you may purchase additional quantities beyond the free quarterly allowance you may be eligible for. Prices and your available allowance for free items are displayed on the item listing.</p>
                            </template>
                        </CollapsiblePanel>

                        <!--<CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                Does Safeco offer logo merchandise?
                            </template>
                            <template v-slot:content>
                                <p>Safeco offers agents access to Safeco branded merchandise at a reasonable price. These can be accessed directly from the <em>Promotional Merchandise</em> tab, under <em>Marketing Materials</em>.</p>
                            </template>
                        </CollapsiblePanel>-->

                        <HeadlineContainer class="my-3" title="Support" />

                        <CollapsiblePanel class="mx-5" ref="collapsiblePanelRef" :open="open">
                            <template v-slot:title>
                                How can I get more help?
                            </template>
                            <template v-slot:content>
                                <p>If you have additional questions, you can:</p>
                                <ul>
                                    <li>Call the Marketing Solutions Center Help Desk at (800) 845-9860 Monday through Friday 9 a.m.-8 p.m. Eastern.</li>
                                    <li>Send an email to <a href="mailto:create@safeco.com">create@safeco.com</a> and a Safeco representative will respond.</li>
                                    <li>Contact your territory manager.</li>
                                </ul>
                            </template>
                        </CollapsiblePanel>

                        <div class="mt-5">
                            <p class="fw-bold">
                                Didn't find what you were looking for in this FAQ?
                            </p>
                            <p>
                                Send an email with your question to <a href="mailto:create@safeco.com">create@safeco.com</a>
                            </p>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </PageTemplate>
</template>

<style lang="scss">
    .frequently-asked-questions {
        .panel-title {
            color: $color-1;
        }

        .panel-content {
            color: $gray-3;
        }

        .py-3 {
            padding-top: 0rem !important;
            padding-bottom: 0rem !important;
        }
    }
</style>