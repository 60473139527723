<script setup>

    // CartItemTile
    // A tile for an item in the cart. 

    // Imports ----
    import { reactive, defineProps, computed, ref} from 'vue';
    import { site } from '@/Site';
    import { deliveryType, productRestrictions } from '../CartPage';
    import { UpdateCartItemQtyForm } from '@dd-nucleus/nucleus-vue';
    import { dropType } from '@/site/areas/personalization/PersonalizeStep3Page';
    import { getUnitOfMeasureFriendlyName } from '@/constants'
    import { formatStatCode } from '@/util';
    import ConfirmationOverlay from '@/site/components/ConfirmationOverlay';
     
    // Props ----
    const props = defineProps({
        // The cart item
        item: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            required: false,
            default: true
        },
        removable: {
            type: Boolean,
            required: false,
            default: true
        },
        isBulkOrder: {
            type: Boolean,
            required: true,
            default: false
        }
    });

    // State ----
    const form = reactive(new UpdateCartItemQtyForm(props.item));
    const showOverlay = ref(false);

    // Computed ----
    const unitPrice = computed(() => {
        if (isKitItem.value
            && props.item
            && props.item.attributes
            && props.item.attributes.OrderState
            && props.item.attributes.OrderState.unitPrice) {
            return site.convertNumberToCurrency(props.item.attributes.OrderState.unitPrice)
        }
        return site.convertNumberToCurrency(props.item.unitPrice)
    });

    const isDigital = computed(() => { return props.item.product.deliveryType == deliveryType.DIGITAL; });
    const isShippingRequired = computed(() => {
        return props.item.product
            && props.item.product.attributes
            && props.item.product.attributes.isRequireShipping
    });

    const isKitItem = computed(() => {
        return props.item && props.item.product && props.item.product.attributes && props.item.product.attributes.isKitItem
    });

    const mailingTotal = computed(() => {
        if (isKitItem.value
            && props.item
            && props.item.attributes
            && props.item.attributes.OrderState
            && props.item.attributes.OrderState.unitPrice) {
            return site.convertNumberToCurrency(props.item.attributes.OrderState.unitPrice * props.item.quantity)
        }
        return site.convertNumberToCurrency(props.item.unitPrice * props.item.quantity)
    });

    const bulkOrderMailingTotal = computed(() => {
        if (isKitItem.value
            && props.item
            && props.item.attributes
            && props.item.attributes.OrderState
            && props.item.attributes.OrderState.unitPrice) {
            return site.convertNumberToCurrency(props.item.attributes.OrderState.unitPrice * props.item.quantity * totalAddresses.value)
        }
        return site.convertNumberToCurrency(props.item.unitPrice * props.item.quantity * totalAddresses.value)
    });

    const isNotForMA = computed(() => {
        if (props.item.product.attributes.productRestriction == productRestrictions.NOTFORMA)
            return 'NOT FOR USE IN MASSACHUSETTS'
        else
            return ''
    });

    const isNotForCO = computed(() => {
      if (props.item.product.attributes.productRestriction == productRestrictions.NOTFORCO)
          return 'NOT FOR USE IN COLORADO'
      else
          return ''
    });

    const dropDetails = computed(() => {
        if (props.item.attributes && props.item.attributes.OrderState && props.item.attributes.OrderState.dropDetails)
            return props.item.attributes.OrderState.dropDetails;
        else if (props.item?.attributes?.BulkOrderState && props.item?.attributes?.BulkOrderState?.dropDetails)
            return props.item.attributes.BulkOrderState?.dropDetails;
        else
            return '';
    });

    const subCodeDetails = computed(() => {
        if (props.item?.attributes?.OrderStates?.length > 0) {
            const agenciesDetails = props.item?.attributes?.agencyDetails;
            return props.item?.attributes?.OrderStates.map(os => {
                return {
                    statCode: os.statCode,
                    quantity: os.targetCount,
                    unitPrice: os.unitPrice,
                    name: agenciesDetails?.find(ad => ad.statId == os.statCode)?.agencyName,
                    totalPrice: site.convertNumberToCurrency(os.targetCount * os.unitPrice)
                }
            });
        }
        else
            return null;
    });

    const showSubCodeDetails = computed(() => {
        return (subCodeDetails.value && subCodeDetails.value.length > 0);
    })

    const frequencyHeadline = computed(() => {
        if (dropDetails.value.dropType?.toLowerCase() == dropType.ONE.toLowerCase())
            return "Single Mailing";
        else
            return "Multiple Drops (" + dropDetails.value.frequency + "), " + dropDetails.value.numberOfMailings + " Mailings"
    });

    const showQuantity = computed(() => {
        return !isDigital.value || (isDigital.value && isShippingRequired.value);
    });

    const showQuantityLabel = computed(() => {
        return props.editable || !isShippingRequired.value
    });

    const showMailingDrop = computed(() => {
        return isDigital.value && dropDetails.value.mailingDetails && dropDetails.value.dropType && dropDetails.value.dropType != 'NONE';
    });

    const totalAddresses = computed(() => {
        return site.cart.current.shipToList?.length;
    });

    // Handlers ----
    async function yes() {
        await site.cart.removeItem(props.item.id);
        showOverlay.value = false;
        site.toast.success("Item/Asset has been removed")
    }

    function no() {
        showOverlay.value = false;
    }

    function showDeleteConfirmation() {
        showOverlay.value = true;
    }


    const hideRelationShipFields = computed(() => {
        if (props.item?.product?.attributes?.isSubscription) {
            return props.item?.product?.attributes?.isSubscription
        }
        return false
    })

    const isFormMessageShown = computed(() => {
        const formObject = JSON.parse(JSON.stringify(form));
        //Show form messages in case of non pod item and non stock messages
        return !(isDigital.value && formObject.isStockExceeded);
    });


</script>

<template>

    <ConfirmationOverlay v-if="showOverlay">
        <template v-slot:headline>
            <h2 class="confirm">Are you sure you want to delete the item from the cart?</h2>
        </template>
        <template v-slot:buttons>

            <button class="btn btn-outline-primary me-2" @click="no">No</button>
            <button class="btn btn-primary" @click="yes">Yes</button>

        </template>
    </ConfirmationOverlay>

    <div class="cart-item-tile">
        <div class="row">

            <div class="col-2 col-sm-2">
                <ProductThumbnail :product="props.item.product" no-image-available-url="/images/no-image.png" />
            </div>

            <div class="col-5 col-sm-5">

                <div class="tags mb-2">
                    <ProductTags :product="props.item.product" />
                </div>

                <!-- Product name and item number -->
                <router-link class="title-link" :to="`/product/${props.item.product.id}`">
                    <div class="name" :title="item.product.name">
                        {{ props.item.product.name }}
                    </div>
                </router-link>

                <div class="item-number"><router-link class="title-link" :to="`/product/${props.item.product.id}`">{{ props.item.product.itemNumber }}</router-link></div>

                <div class="fs-6 fw-bold text-danger">{{isNotForMA}}</div>
                <div class="fs-6 fw-bold text-danger">{{isNotForCO}}</div>

                <div class="messages">
                    <MessageList :messages="props.item.messages" :add-box="false" />
                </div>
                <div class="d-sm-none d-block">
                    <div class="fw-bold" v-if="showQuantityLabel">Quantity:</div>
                    <FormContainer :form="form" v-if="showQuantity">
                        <div class="flex-fill">
                            <CartItemQty :item="props.item" :editable="props.editable" />
                            <FormMessages field-name="quantity" :add-box="false" class="mt-2" v-if="isFormMessageShown" />
                            <FieldMessages field-name="quantity" class="mt-2" />
                        </div>

                    </FormContainer>

                    <div v-else>
                        {{props.item.quantity}}
                    </div>

                </div>
            </div>

            <div class="col-5 col-sm-3" v-if="!hideRelationShipFields">

                <div>
                    <strong class="fs-2">{{unitPrice}}</strong> {{getUnitOfMeasureFriendlyName(props.item.product)}}
                </div>

                <div class="fw-bold mt-3" v-if="props.isBulkOrder">

                    <div>Total Addresses:</div>

                    <div class="fs-2">{{totalAddresses}}</div>
                </div>

                <div class="fw-bold mt-3" v-if="props.isBulkOrder">

                    <div>Total:</div>

                    <div class="fs-2">{{bulkOrderMailingTotal}}</div>
                </div>

                <div class="fw-bold mt-3" v-else>

                    <div>Total:</div>

                    <div class="fs-2">{{mailingTotal}}</div>
                </div>

            </div>

            <div class="d-none d-sm-block col-sm-2" v-if="!hideRelationShipFields">
                <div class="fw-bold" v-if="showQuantityLabel">Quantity:</div>
                <FormContainer :form="form" v-if="showQuantity">
                    <div class="flex-fill">
                        <CartItemQty :item="props.item" :editable="props.editable" />
                        <FormMessages field-name="quantity" :add-box="false" class="mt-2" v-if="isFormMessageShown" />
                        <FieldMessages field-name="quantity" class="mt-2" />
                    </div>
                </FormContainer>

                <div v-else>
                    {{props.item.quantity}}
                </div>

            </div>

        </div>

        <div class="row" v-if="showSubCodeDetails">

            <div class="offset-2 col-8">
                <div class="subcode-details p-3">
                    <div>
                        <table class="table">
                            <thead>
                                <tr class="bg-color-3-lt">
                                    <th class="border-end border-gray-0">Sub Code</th>
                                    <th class="border-end border-gray-0">Agency Name</th>
                                    <th class="border-end border-gray-0">Quantity</th>
                                    <th class="border-end border-gray-0">Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in subCodeDetails" :key="item.id" class="border-bottom border-gray-2">
                                    <td>{{ formatStatCode(item.statCode) }}</td>
                                    <td>{{ item.name }}</td>
                                    <td>{{ item.quantity }}</td>
                                    <td>{{ item.totalPrice }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="showMailingDrop">

            <div class="offset-2 col-8">
                <div class="mailing-drops p-3">

                    <div>
                        <strong>Mail Frequency:</strong> {{frequencyHeadline}}
                    </div>

                    <div class="row" v-for="drop in dropDetails.mailingDetails" :key="drop.mailDate">
                        <div class="col-3">
                            {{site.formatDisplayDate(drop.mailDate)}}
                        </div>
                        <div class="col">
                            {{drop.quantity}}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" v-if="!isKitItem">
            <div class="offset-2 col-8">
                <div class="item-actions mt-4" v-if="props.removable">
                    <ul>
                        <li>
                            <a href="#" class="no-underline" @click="showDeleteConfirmation">
                                Remove
                            </a>
                        </li>
                    </ul>
                </div>

                <div class="favorite">
                    <ProductFavoriteIcon class="fs-2" :product="props.item.product" />
                </div>
            </div>
        </div>

    </div>
</template>
<style lang="scss">
    .cart-item-tile {
        padding: 1rem 0;

        .name {
            font-size: 1.0rem;
            line-height: 1.1rem;
        }

        a.title-link, a.title-link:visited, a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .n-product-add-to-cart-qty .text-box {
            border-color: #dddddd;
        }

        .favorite {
            .n-product-favorite-icon {
                color: $color-2;
            }
        }

        .mailing-drops {
            background: $color-2-xlt;
        }

        .item-actions {
            ul {
                padding: 0;
                margin: 0;
                font-size: 1rem;
            }

            ul li {
                display: inline;

                &:after {
                    content: "|";
                    margin: 0 5px;
                }

                &:last-child:after {
                    content: '';
                    margin: 0;
                }
            }
        }
    }

    .subcode-details {
        .table > :not(caption) > * > * {
            border-bottom: unset;
        }
        // Another weird border top on the table
        .table > :not(:first-child) {
            border-top: unset;
        }

        thead {
            background: $color-2;
            color: $gray-4;
            border-color: $gray-0;
        }

        .price-message {
            color: $error;
            font-size: .75rem;
        }

        tr {
            border-color: #c0bfc0;
            border-bottom: 1px solid #dee2e6;

            &:last-child {
                border-bottom: unset;
            }

            td {
                width: 26%;
            }
        }
    }
</style>
