<script>

    // OrderItemTile
    // A tile for an item in an order. Typically used when viewing order detail.
    // TODO: Move this to a more appropriate folder

    // Setup ---
    // Use the values below to modify how the tile appears
    const setup = {
        tileHeight: '8rem',           // Height of the full tab, fixed for alignment purposes
        productNameLines: 2            // Number of lines product name is allowed to wrap before being trimmed with ellipses
    };

    export const Setup = setup;

</script>

<script setup>

    // Imports ----
    import { defineProps, computed } from 'vue';

    // Props ----
    const props = defineProps({
        // The cart item
        item: {
            type: Object,
            required: true
        }
    });

    const hideRelationShipFields = computed(() => {
        if (props.item?.product?.attributes?.isSubscription) {
            return props.item?.product?.attributes?.isSubscription
        }
        return false
    })

</script>

<template>

    <div class="order-item-tile" :style="{ 'height': setup.tileHeight }">
        <div class="inner-row d-flex flex-row">
            <div class="left">
                <ProductThumbnail :product="props.item.product" :height="setup.tileHeight" no-image-available-url="/images/no-image.png" />
            </div>
            <div class="middle flex-fill">
                <!-- Stretchy middle part-->
                <div class="tags mb-2">
                    <ProductTags :product="props.item.product" />
                </div>

                <!-- Product name and item number -->
                <router-link class="title-link" :to="`/product/${props.item.product.id}`">
                    <div class="name" v-snip="{ lines: setup.productNameLines }" :title="item.product.name">
                        {{ props.item.product.name }}
                    </div>
                </router-link>
                <div class="item-number"><router-link class="title-link" :to="`/product/${props.item.product.id}`">{{ props.item.product.itemNumber }}</router-link></div>

                <div class="favorite">
                    <ProductFavoriteIcon :product="props.item.product" label="Add as Favorite" selected-label="Favorite" />
                </div>
            </div>
            <!-- Fixed right panel -->
            <div v-if="!hideRelationShipFields" class="right d-flex flex-column" :style="{ height: setup.tileHeight }">
                <div class="flex-fill">
                    <ItemQty :item="props.item" />
                </div>
                <div class="messages">
                    <MessageList :messages="props.item.messages" :add-box="false" />
                </div>
                <div class="shipment-status">
                    <div v-if="props.item.status === 'Delivered'" class="complete">
                        <i class="bi bi-check-circle-fill"></i> SHIPPED
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

    .order-item-tile {
        .inner-row {
            border-bottom: 1px solid $gray-20;
            padding-bottom: 1rem;
        }

        .left {
            width: 8rem;
        }

        .name {
            font-size: 1.0rem;
            line-height: 1.1rem;
        }

        a.title-link, a.title-link:visited, a.title-link:hover {
            color: var(--bs-body-color);
            text-decoration: none;
        }

        .item-number a.title-link {
            font-size: 0.7rem;
            color: $gray-50;
        }

        .middle {
            padding-left: 1rem;
        }

        .favorite {
            margin-top: 1rem;
        }

        .right {
            width: 14rem;
        }

        .shipment-status {
            .complete {
                color: $ok;
                font-weight: $bold;
            }
        }
    }

</style>