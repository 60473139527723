<script type="text/javascript">(function (w, d, s, r, k, h, m) { if (w.performance && w.performance.timing && w.performance.navigation && !w.s247r) { w[r] = w[r] || function () { (w[r].q = w[r].q || []).push(arguments) }; h = d.createElement('script'); h.async = true; h.setAttribute('src', s + k); d.getElementsByTagName('head')[0].appendChild(h); (m = window.onerror), (window.onerror = function (b, c, d, f, g) { m && m(b, c, d, f, g), g || (g = new Error(b)), (w[r].q = w[r].q || []).push(["captureException", g]); }) } })(window, document, '//static.site24x7rum.com/beacon/site24x7rum-min.js?appKey=', 's247r', 'd7f30b981441c88b57b7355aec082fee');</script>
<script setup>

    // GenericFooter
    // Used as a footer for generic footer excluding contact us/feedback links.

    import { PageFooterContainer } from '@dd-nucleus/nucleus-vue';

    const date = new Date()

</script>

<template>
    <PageFooterContainer>
        <div class="row">
            <div class="col-2">
                <img src="@/../public/images/footer-logo.png" alt="Safeco Insurance, a Liberty Mutual Company">
            </div>
            <div class="col">
                <div class="trademark">
                    Safeco Insurance. For Agent Use Only.
                    <a href="https://open.dpec.safeco.com/viewtermsofuse" target="_blank">Terms of Use</a>
                    &copy; {{ date.getFullYear() }} Liberty Mutual Insurance.
                    <span class="california-icon">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6H6C2.685 6 0 8.685 0 12C0 15.315 2.685 18 6 18H18C21.315 18 24 15.315 24 12C24 8.685 21.315 6 18 6ZM19.83 13.735C19.975 13.88 19.975 14.12 19.83 14.265C19.755 14.34 19.66 14.375 19.565 14.375C19.47 14.375 19.375 14.34 19.3 14.265L17.565 12.53L15.83 14.265C15.755 14.34 15.66 14.375 15.565 14.375C15.47 14.375 15.375 14.34 15.3 14.265C15.155 14.12 15.155 13.88 15.3 13.735L17.035 12L15.3 10.265C15.155 10.12 15.155 9.88 15.3 9.735C15.445 9.59 15.685 9.59 15.83 9.735L17.565 11.47L19.3 9.735C19.445 9.59 19.685 9.59 19.83 9.735C19.975 9.88 19.975 10.12 19.83 10.265L18.095 12L19.83 13.735ZM6 17.25C3.105 17.25 0.75 14.895 0.75 12C0.75 9.105 3.105 6.75 6 6.75H13.69L9.315 17.25H6.005H6ZM9.66 10.265L5.395 14.53L3.39 12.525C3.245 12.38 3.245 12.14 3.39 11.995C3.535 11.85 3.775 11.85 3.92 11.995L5.395 13.47L9.13 9.735C9.275 9.59 9.515 9.59 9.66 9.735C9.805 9.88 9.805 10.12 9.66 10.265Z" fill="white" />
                        </svg>
                    </span>
                    <a class="california-link" href="https://privacyportal.onetrust.com/hosted-webform/consent/50577d86-8d39-4310-9583-33e6d3da8e17/9b108b70-4ef6-4d93-b7ec-60e41f03b292" target="_blank">Your California Privacy Choices</a>
                    <span class="privacy-policy">
                        <a href="https://www.libertymutualgroup.com/about-lm/corporate-information/privacy-policy" target="_blank">Privacy Policy</a>
                    </span>
                </div>
            </div>
        </div>

    </PageFooterContainer>
</template>

<style lang="scss">

    .n-footer .trademark {
        margin-top: 1rem;
        font-size: 0.75rem;

        a, a:visited {
            color: $footer-text-color;
        }

        a:hover {
            color: $footer-text-hover-color;
        }

        .california-link {
            margin-left: 4px;
            font-weight: 100;
        }

        .privacy-policy {
            margin-left: 12px;
            font-weight: 100;
        }

        .california-icon {
            margin-left: 12px;
        }
    }
</style>